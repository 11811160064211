(function() { 

/**************************
 * CSRF対策
 **************************/
	TOKEN_FOR_AJAX_NAME = 'E13CT'; //CSRF対策（変数名）
	TOKEN_FOR_AJAX = ''; //CSRF対策（変数を入れる）
	_data = {};
	_data[TOKEN_FOR_AJAX_NAME] = TOKEN_FOR_AJAX;
	

/**************************
 * 効果タグ挿入
 **************************/
 	AROUTE_EFFECT = '';
 	AROUTE_EFFECT_NAME = '';
 	
	
})();

$(function(){
	$(".js_windowClose").click(function(){
		open(location, '_self').close();
	});

	if($("input[name="+TOKEN_FOR_AJAX_NAME+"]").size() > 0){
		TOKEN_FOR_AJAX = $("input[name="+TOKEN_FOR_AJAX_NAME+"]").eq(0).val();
		_data[TOKEN_FOR_AJAX_NAME] = TOKEN_FOR_AJAX;
	}
	
	if($("#aroute_effect").size() > 0){
		var _vdata = [];
		
		_vdata = [
			{
				name : $("#aroute_effect").attr("name"),
				value : $("#aroute_effect").val()
			},
		]
		
		if($("#featureID").size() > 0){
			_vdata = [
				{
					name : $("#aroute_effect").attr("name"),
					value : $("#aroute_effect").val()
				},
				{
					name : "FeatureID",
					value : $("#featureID").val()
				}
			]
		}
		
		$(document).on("click","a.js_aroute",function(){
			if(document.URL.match(/recommend/)){
				if(!$(this).hasClass("recommend")){
					if((_vdata[0]['name']=="aroute") && (_vdata[0]['value']=="503")){
						_vdata[0]['value'] = "32";
					}
				}else{
					if((_vdata[0]['name']=="aroute") && (_vdata[0]['value']=="32")){
						_vdata[0]['value'] = "503";
					}
				}
			}
			globalPage.toPageWithValues(_vdata,$(this).attr("href"),$(this).attr("target"));
			return false;
		})

	}

	if($(".js_aroute_effect_value").size() > 0){
		$(document).on("click","a.js_aroute_each",function(){
			var _name = $(this).attr("class").match(/jar_(.+)/);
			if(_name){
				var _id = _name[1];
				if($("#"+_id).size() > 0){
					globalPage.toPageWithValue($("#"+_id).attr("name"),$("#"+_id).val(),$(this).attr("href"),$(this).attr("target"));
					return false;
				}
			}
		})
		
	}
	
	
	if($("#modalWindow").size() > 0){
		var alert_modal = $("#modalWindow").myModal({},$(".js_spWrapper"));
		var defaultHide = $("#modalWindow").data('defaultHide');
		if (defaultHide) {
			alert_modal.hideModal();
		} else {
			alert_modal.open(function(){});
		}
		$("#modalOff_alert").click(function(){
			alert_modal.hideModal();
			globalHeader._open();
		})

		$("#modalOff").click(function(){
			alert_modal.hideModal();
		})	
	}	
})

var globalPage = {

	toPageWithValue : function(_name,_value,_url,_target){
			if(_url.match(/\?/)){
				_url += "&" + _name + "=" + _value;
			}else{
				_url += "?" + _name + "=" + _value;
			}
			if(_target != null && _target != undefined){
				if(_target == "_blank"){
					window.open(_url,"");
				}else{
					window.open(_url,_target);
				}
			}else{
				location.href = _url;
			}
	},
	
	toPageWithValues : function(_vdata,_url,_target){
			var _n = _vdata.length;
			for(var _i = 0; _i < _n; _i++){
				var _name = _vdata[_i]['name'];
				var _value = _vdata[_i]['value'];
				if(_url.match(/\?/)){
					_url += "&" + _name + "=" + _value;
				}else{
					_url += "?" + _name + "=" + _value;
				}

			}
			if(_target != null && _target != undefined){
				if(_target == "_blank"){
					window.open(_url,"");
				}else{
					window.open(_url,_target);
				}
			}else{
				location.href = _url;
			}
	}
	

}
