(function () {
  function ucFirst(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  var AssociableCheckbox = function($container) {
    var $associableCheckbox = $container.find('.js_associableCheckbox');
    var propagation = function($context, isChecked, propagationType) {
      $context.prop('checked', isChecked).trigger("associableChange");
      var $hasManyCheckboxes = $context.data('hasManyCheckboxes');
      if ($hasManyCheckboxes !== undefined && $.inArray(propagationType, ['together', 'hasMany']) !== -1) {
        $hasManyCheckboxes.each(function(){ propagation($(this), isChecked, 'hasMany'); });
      }
      var $belongsCheckboxes = $context.data('belongsToCheckbox');
      if ($belongsCheckboxes !== undefined && $.inArray(propagationType, ['together', 'belongs']) !== -1) {
        var $siblingCheckboxes = $context.data('siblingCheckboxes');
        var isAllSiblingCheckboxCheckded =
          $siblingCheckboxes.length === $siblingCheckboxes.filter(':checked').length;
        $belongsCheckboxes.each(function() { propagation($(this), isAllSiblingCheckboxCheckded, 'belongs'); });
      }
    };
    $associableCheckbox
      .each(function() {
        var $this = $(this);
        var hasManyCheckboxClass = $this.data('hasManyCheckboxClass');
        if (hasManyCheckboxClass === undefined) {
          return;
        }
        var $hasManyCheckboxes = $container.find('.'+hasManyCheckboxClass);
        $this.data('hasManyCheckboxes', $hasManyCheckboxes);
        $hasManyCheckboxes.data('siblingCheckboxes', $hasManyCheckboxes);
        $hasManyCheckboxes.data('belongsToCheckbox', $this);
      })
      .on('click', function(event) {
        event.preventDefault();
        setTimeout(function() {
          $this.prop('checked', !$this.is(':checked')).trigger("associableChange");
        }, 1);
        var $this = $(this);
        propagation($this, $this.is(':checked'), 'together');
        //$this.trigger('change');
      });
    $associableCheckbox.filter(':checked').each(function() {
      propagation($(this), $(this).is(':checked'), 'together');
    });
      return $associableCheckbox;
  };
  window.AssociableCheckbox = AssociableCheckbox;
})();
