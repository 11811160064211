
/**
form要素を親にとり、指定したurlにデータを送信する。
dom@class myDomControl
 */

/**
スクロールでボタンが出たり消えたり
@param jqParentCheckBox
@param jqChildrenCheckBoxes
@return 戻り値はなし。
@example
$('親チェックボックス').myLumpCheck('子チェックボックスを含むDOM');
 */

/**
アコーディオン
@method myAutoHeight
@param this  折りたたむDOMオブジェクト
@param h {int} 折りたたむ高さ。thisこの数値以上の高さになっていた場合、この数値の高さに折りたたまれる
@param trigObj {DOMオブジェクト} 「さらに表示」「閉じる」リンク。
@return 戻り値はなし。
@example
$('DOM(長文オブジェクト)').myAutoHeight(200,'DOM近くのaタグなど');
 */

/**
アコーディオン（Ajaxでオープン）
@method myAutoHeight
@param this  折りたたむDOMオブジェクト
@param h {int} 折りたたむ高さ。thisこの数値以上の高さになっていた場合、この数値の高さに折りたたまれる
@param trigObj {DOMオブジェクト} 「さらに表示」「閉じる」リンク。
@return 戻り値はなし。
@example
$('DOM(長文オブジェクト)').myAutoHeight(200,'DOM近くのaタグなど');
 */

/**
一定以上の高さのDOMを折りたたむ処理
@method myAutoHeight
@param this  折りたたむDOMオブジェクト
@param h {int} 折りたたむ高さ。thisこの数値以上の高さになっていた場合、この数値の高さに折りたたまれる
@param trigObj {DOMオブジェクト} 「さらに表示」「閉じる」リンク。
@return 戻り値はなし。
@example
$('DOM(長文オブジェクト)').myAutoHeight(200,'DOM近くのaタグなど');
 */
var accordion, bubbleUp, emptyFunction, myAccordionByAjax, myAdaptToDom, myAdaptToDomWithObject, myAutoHeight, myAutoHeightByCount, myExtraBlock, myPopup, myScrollTo, myToTopSlider, scrollSticky, sprintf, _ellipsis,
  __hasProp = {}.hasOwnProperty;

_ellipsis = function(obj) {
  var $inner, $outer, innerHeight, outerHeight;
  $outer = obj;
  $outer.wrapInner("<div />");
  $inner = $outer.children();
  outerHeight = $outer.height();
  innerHeight = $inner.height();
  if (innerHeight > outerHeight) {
    $("<span />", {
      "class": "ellipsis",
      text: "…"
    }).appendTo($outer);
  }
};


/**
吹き出し出す
@method myAutoHeight
@param this  折りたたむDOMオブジェクト
@param h {int} 折りたたむ高さ。thisこの数値以上の高さになっていた場合、この数値の高さに折りたたまれる
@param trigObj {DOMオブジェクト} 「さらに表示」「閉じる」リンク。
@return 戻り値はなし。
@example
$('DOM(長文オブジェクト)').myAutoHeight(200,'DOM近くのaタグなど');
 */


/**
「TOPへ」のスライダーボタン設置
@method myToTopSlider
@param this  スライドするオブジェクト
@param obj   リンクにするオブジェクト
 */


/**
配列を特定オブジェクトにドンする
@method myAdaptToDom
@param this  スライドするオブジェクト
@param obj   リンクにするオブジェクト
 */

sprintf = function(text) {
  var args, i;
  i = 1;
  args = arguments;
  return text.replace(/%s/g, function() {
    if (i < args.length) {
      return args[i++];
    } else {
      return "";
    }
  });
};

$.fn.extend({
  myStickyButton: function(sticky, t, b, option) {
    if (option == null) {
      option = {
        display: true
      };
    }
    if (sticky.length === 0) {
      return false;
    }
    sticky.scrollSticky = new scrollSticky(this, sticky, t, b, option);
    return sticky.scrollSticky;
  }
});

scrollSticky = function(jqWrapper, jqSticky, jqScrollTop, jqScrollBottom, option) {
  var showSticky, thisObj, hideStickyCallback, showStickyCallback;
  thisObj = this;
  if (jqSticky.data("defaultDisplay") != null) {
    option.display = jqSticky.data("defaultDisplay");
  }
  this.display = option.display;
	hideStickyCallback = option.hideStickyCallback || function() {};
	showStickyCallback = option.showStickyCallback || function() {};
  if ($.isNumeric(jqScrollTop)) {
    this.t = jqScrollTop;
    this.tFlg = false;
  } else {
    this.t = jqScrollTop.offset().top - jqWrapper.height();
    this.tFlg = true;
  }
  if ($.isNumeric(jqScrollBottom)) {
    this.b = jqScrollBottom;
    this.bFlg = false;
  } else {
    this.b = jqScrollBottom.offset().top - jqWrapper.height();
    this.bFlg = true;
  }
  showSticky = function() {
    var sh;
    sh = jqWrapper.scrollTop();
    if (thisObj.tFlg) {
      thisObj.t = jqScrollTop.offset().top + jqScrollTop.outerHeight();
    }
    if (thisObj.bFlg) {
      var stickyOffset = jqSticky.data("disableStickyOffset") ? 0 : 50;
      thisObj.b = jqScrollBottom.offset().top - jqWrapper.height() + jqSticky.height() - stickyOffset;
    }
    if (thisObj.display && sh >= thisObj.t && sh <= thisObj.b) {
      jqSticky.removeClass("js_stHide");
      showStickyCallback();
    } else {
      jqSticky.addClass("js_stHide");
      hideStickyCallback();
    }
  };
  jqWrapper.on("scroll touchmove resize", showSticky);
  this.show = function() {
    this.display = true;
    return showSticky();
  };
  showSticky();
};

$.fn.extend({
  myAccordion: function(animateFlg, callback_open, callback_close) {
    var i, _i, _len, _results;
    if (this.length === 0) {

      /*
        何もしない
       */
    } else if (this.length === 1) {
      return this.accordion = new accordion(this, this.find(".js_title").first(), this.find(".js_content").first(), animateFlg, callback_open, callback_close);
    } else {
      _results = [];
      for (_i = 0, _len = this.length; _i < _len; _i++) {
        i = this[_i];
        _results.push($(i).myAccordion(animateFlg, callback_open, callback_close));
      }
      return _results;
    }
  }
});

accordion = function(jqTarget, jqLink, jqContent, flg, callback_open, callback_close) {
  var thisObj;
  thisObj = this;
  this.openT = jqLink.find(".js_openTxt");
  this.closeT = jqLink.find(".js_closeTxt");
  this.closeT.addClass("js_acHideImp");
  if (callback_open != null) {
    this.callback_open = callback_open;
  } else {
    this.callback_open = function() {};
  }
  if (callback_close != null) {
    this.callback_close = callback_close;
  } else {
    this.callback_close = function() {};
  }
  this.target = jqTarget;
  this.content = jqContent;
  this.link = jqLink;
	if ( jqContent.find('input:checkbox:checked').length < 1 ) {
		this.content.addClass("js_acHideImp");
	}
	else {
		this.link.addClass("be_open");
	}
  this.flg = flg;
  this.top = this.target.offset().top - 50;
  this.link.click(function() {
    var t;
    if (thisObj.flg) {
      if (thisObj.content.is(":visible")) {
        thisObj.callback_close(thisObj.target);
        thisObj.openT.removeClass("js_acHideImp");
        thisObj.closeT.addClass("js_acHideImp");
        thisObj.link.removeClass("be_open");
        t = thisObj.target.offset().top - 10;
        if ($(window).scrollTop() > t) {
          $("body,html").animate({
            scrollTop: t
          }, 250);
        }
      }
      thisObj.content.slideToggle(250, function() {
        if (thisObj.content.is(":visible")) {
          thisObj.callback_open(thisObj.target);
          thisObj.openT.addClass("js_acHideImp");
          thisObj.closeT.removeClass("js_acHideImp");
          thisObj.link.addClass("be_open");
        }
      });
    } else {
      thisObj.content.toggleClass("js_acHideImp");
      if (thisObj.content.is(":visible")) {
        thisObj.callback_open(thisObj.target);
        thisObj.openT.removeClass("js_acHideImp");
        thisObj.closeT.addClass("js_acHideImp");
        thisObj.link.addClass("be_open");
      } else {
        thisObj.callback_close(thisObj.target);
        thisObj.openT.addClass("js_acHideImp");
        thisObj.closeT.removeClass("js_acHideImp");
        thisObj.link.removeClass("be_open");
        t = thisObj.target.offset().top - 10;
        if ($(window).scrollTop() > t) {
          $("body,html").animate({
            scrollTop: t
          }, 10);
        }
      }
    }
  });
};

$.fn.extend({
  myAccordionByAjax: function(url, callback_open, callback_load, callback_close) {
    new myAccordionByAjax(this, url, callback_open, callback_load, callback_close);
  }
});

myAccordionByAjax = function(jqContainer, url, callback_open, callback_load, callback_close) {
  var thisObj;
  thisObj = this;
  this.container = jqContainer;
  this.switcher = jqContainer.find(".jsTitle");
  this.url = url;
  if (callback_open != null) {
    this.callback_open = callback_open;
  } else {
    this.callback_open = function() {};
  }
  if (callback_load != null) {
    this.callback_load = callback_load;
  } else {
    this.callback_load = function() {};
  }
  if (callback_close != null) {
    this.callback_close = callback_close;
  } else {
    this.callback_close = function() {};
  }
  this.content = jqContainer.find(".jsContent");
  this.loading = jqContainer.find(".jsLoading");
  this.switcher.click(function() {
    if (!thisObj.container.hasClass("jsDone")) {
      thisObj._load();
    } else {
      thisObj._open();
    }
  });
  this._load = function() {
    myAjaxFunc.myContentLoad(url, (function(r) {
      thisObj.content.html(r);
      thisObj.container.addClass("jsDone");
      thisObj.content.slideDown(250, function() {
        thisObj.callback_load();
        thisObj.callback_open();
      });
    }), this.loading);
  };
  this._open = function() {
    this.content.slideToggle(250, function() {
      if ($(this).filter(":visible").size() > 0) {
        thisObj.callback_open();
      } else {
        thisObj.callback_close();
      }
    });
  };
};

$.fn.extend({
  myAutoHeight: function(h) {
    return new myAutoHeight(this, h);
  }
});

myAutoHeight = function(jqTarget, heightDelimiter) {
  var thisObj;
  this.jqTarget = jqTarget.find(".js_content");
  this.jqInner = jqTarget.find(".js_Inner");
  this.jqTrigger = jqTarget.find(".js_trigger");
  this.openTxt = jqTarget.find(".js_openTxt");
  this.closeTxt = jqTarget.find(".js_closeTxt");
  this.delimiter = heightDelimiter;
  this.autoOverFlow = false;
  this.top = this.jqTarget.offset().top - 50;
  thisObj = this;
  thisObj.jqTarget.data("hD", thisObj.delimiter);
  thisObj.jqTrigger.click(function() {
    thisObj.trig();
  });
  this.init = function() {
    var h;
    h = thisObj.jqTarget.height();
    if ( thisObj.jqTarget.data('ahMaxHeight') !== undefined && thisObj.jqTarget.data('ahMaxHeight') < h ) {
      h = thisObj.jqTarget.data('ahMaxHeight');
      thisObj.autoOverFlow = true;
    }
    thisObj.jqTarget.data("defh", h);
    if (h < thisObj.delimiter) {
      thisObj.jqTarget.removeClass("jsAutoThin");
      thisObj.jqTrigger.addClass("ah_hide");
    } else {
      thisObj.jqTarget.addClass("jsAutoThin");
      thisObj.jqTrigger.removeClass("ah_hide");
      thisObj.jqTarget.height(thisObj.jqTarget.data("hD"));
    }
  };
  this.trig = function() {
    var t;
    thisObj.jqTarget.toggleClass("jsAutoThin");
    if (thisObj.jqTarget.hasClass("jsAutoThin")) {
      if ( thisObj.autoOverFlow ) {
        thisObj.jqTarget.removeClass('js_ahOverFlowAuto');
      }
      thisObj.jqTarget.animate({
        height: thisObj.jqTarget.data("hD")
      }, 250);
      thisObj.openTxt.removeClass("js_ahHide");
      thisObj.closeTxt.addClass("js_ahHide");
      t = thisObj.jqTarget.offset().top - 10;
      if ($(window).scrollTop() > t) {
        $("html,body").animate({
          scrollTop: t
        }, 250);
      }
    } else {
      if ( thisObj.autoOverFlow ) {
        thisObj.jqTarget.addClass('js_ahOverFlowAuto');
      }
      thisObj.jqTarget.animate({
        height: thisObj.jqTarget.data("defh")
      }, 250);
      thisObj.openTxt.addClass("js_ahHide");
      thisObj.closeTxt.removeClass("js_ahHide");
    }
    return false;
  };
  this.hide = function() {
    if (this.jqTarget.data("hD") < this.jqTarget.data("defh")) {
      thisObj.openTxt.removeClass("js_ahHide");
      thisObj.closeTxt.addClass("js_ahHide");
      thisObj.jqTarget.animate({
        height: jqTarget.data("hD")
      }, 250);
      thisObj.jqTarget.addClass("jsAutoThin");
    }
  };
  this.init();
};

myAutoHeightByCount = {
  init: function(jqTarget, jqTrigger, delimiter) {
    var lef, myLeng, newH, newm, tempTx, textTrim, textTrim_after, thisObj;
    tempTx = jqTarget.html().replace(/<br>/g, "\n");
    myLeng = jqTarget.text().length;
    lef = myLeng - delimiter;
    if (lef > 0) {
      textTrim = jqTarget.text().substr(0, delimiter);
      textTrim_after = jqTarget.text().substr(delimiter, lef);
      newH = "<span class='ah_before'>" + textTrim + "</span><span class='ah_after'>" + textTrim_after + "</span><span class='ah_ellipsis'>...続きを見る</span>";
    }
    newm = newH.replace(/\n/g, "<br>");
    jqTarget.html(newm);
    thisObj = this;
    thisObj.resize(jqTarget, jqTrigger, delimiter);
    jqTrigger.click(function() {
      thisObj.trig(jqTrigger, jqTarget, delimiter);
    });
    return false;
  },
  resize: function(jqTarget, jqTrigger, heightDelimiter) {
    jqTarget.find(".ah_after").addClass("ah_hide");
    jqTarget.find(".ah_ellipsis").removeClass("ah_hide");
    return false;
  },
  trig: function(jqTrigger, jqTarget, h) {
    var jqhidden;
    jqhidden = jqTarget.find(".ah_after");
    jqhidden.toggleClass("ah_hide");
    jqTarget.find(".ah_ellipsis").toggleClass("ah_hide");
    if (jqhidden.hasClass("ah_hide")) {
      jqTrigger.text("さらに表示");
    } else {
      jqTrigger.text("閉じる");
    }
    return false;
  }
};

$.fn.extend({
  myBubble: function(duration, cbFunc) {
    return new bubbleUp(this, duration, cbFunc);
  }
});

bubbleUp = function(obj, duration, cbFunc) {
  var thisObj;
  thisObj = this;
  thisObj.wrapper = obj;
  thisObj.link = obj.find(".js_trigger");
  thisObj.body = obj.find(".js_bubble");
  thisObj.duration = duration;
  thisObj.cb = cbFunc;
  thisObj.body.addClass("js_bbHide");
  thisObj.link.click(function() {
    thisObj.popOff();
  });
	this.popOff = function(upto){
		if(upto == null){
			upto = 15;
		}
		var ww = thisObj.wrapper.width();
		var bw = thisObj.body.width();
		var l = ww / 2 - bw / 2;
		var wh = thisObj.link.height();
		var bh = thisObj.body.height();
		var t = wh / 4 - bh;
		thisObj.body
			.css("left",l + "px")
			.animate({ "top" : '-'+upto , "opacity" : "show" }, duration)
		thisObj.body
			.fadeOut(duration*1.1,function(){thisObj.cb(thisObj.wrapper)});
  };
};

$.fn.extend({
  myToTopSlider: function(jqLink) {
    new myToTopSlider(this, jqLink);
  }
});

myToTopSlider = function(jqWindow, jqLink) {
  jqLink.click(function() {
    var nh, v;
    nh = $(window).scrollTop();
    v = nh;
    jqWindow.animate({
      scrollTop: 0
    }, v);
  });
};

$.fn.extend({
  myAdaptToDom: function(ary) {
    new myAdaptToDom(this, ary);
  }
});

myAdaptToDom = function(jqDom, jqAry) {
  var iad, template, tptxt;
  tptxt = "";
  if (jqDom.size() > 0) {
    template = jqDom.get(0).firstChild.nodeValue;
    iad = 0;
    while (iad < jqAry.length) {
      if (jqAry[iad] !== "") {
        tptxt += sprintf(template, iad, jqAry[iad]);
      }
      iad++;
    }
    jqDom.html(tptxt);
    jqDom.prepend("<!--" + template + "-->");
  }
};

$.fn.extend({
  myAdaptToDomWithObject: function(objects) {
    new myAdaptToDomWithObject(this, objects);
  }
});

myAdaptToDomWithObject = function(jqDom, objects) {
  var key, object, objectTemplate, replaceText, template, tmpText, _i, _len;
  var vanillaDom = (function() {
    var id = jqDom.attr('id');
    if ( id === undefined ) {
      id = Math.floor(Math.random()*1000) + new Date().getTime().toString();
      jqDom.attr('id', id);
    }
    return document.getElementById(id);
  })();
  tmpText = '';
  if (jqDom.size() > 0) {
    template = vanillaDom.firstChild.nodeValue;
    for (_i = 0, _len = objects.length; _i < _len; _i++) {
      object = objects[_i];
      objectTemplate = template;
      for (key in object) {
        if (!__hasProp.call(object, key)) continue;
        replaceText = new RegExp('{{' + key + '}}');
        objectTemplate = objectTemplate.replace(replaceText, object[key]);
      }
      tmpText += objectTemplate;
    }

    vanillaDom.innerHTML = "<!--" + template + "-->"+ tmpText;
  }
};


/**
特定オブジェクトへスクロール
@method myToTopSlider
@param this  スライドするオブジェクト
@param obj   リンクにするオブジェクト
 */

$.fn.extend({
  myScrollTo: function(toObj, speed) {
    new myScrollTo(this, toObj, speed);
  }
});

myScrollTo = function(jqWindow, toObj, speed) {
  var nh, th, tim;
  nh = $(window).scrollTop();
  th = toObj.offset().top - 50;
  tim = Math.abs(nh - th) * speed;
  $("html,body").animate({
    scrollTop: th
  }, tim);
};

emptyFunction = function() {};

$.fn.extend({
  myExtraBlock: function(animateFlg, callbackOpen, callbackClose) {
    if (animateFlg == null) {
      animateFlg = true;
    }
    if (callbackOpen == null) {
      callbackOpen = emptyFunction;
    }
    if (callbackClose == null) {
      callbackClose = emptyFunction;
    }
    new myExtraBlock(this, animateFlg, callbackOpen, callbackClose);
    return this;
  }
});

myExtraBlock = function(jqDom, animateFlg, callbackOpen, callbackClose) {
  var _this, hiddenFilter;
  _this = this;
  this.jqDom = jqDom;
  this.trigger = this.jqDom.find('.js_ebTrigger');
  this.elements = this.jqDom.find('.js_ebElements');
	for (var i=0; i < this.elements.length; ++i) {
		var jqElement = $(this.elements[i]);
		if ( ! jqElement.data('ebDefaultOpen') ) {
			jqElement.hide();
		}
	}
	hiddenFilter = function() {
		return $(this).is(':hidden');
	};
	var hiddenElements;
	hiddenElements = this.elements.filter(hiddenFilter);
	if (hiddenElements.length < 1) {
		return _this.trigger.hide();
	}
  return this.trigger.on('click', function(ev) {
    var hiddenElements;
    hiddenElements = _this.elements.filter(hiddenFilter);
    $(hiddenElements[0]).slideDown();
    if (hiddenElements.length <= 1) {
      return _this.trigger.slideUp();
    }
  });
};

$.fn.extend({
  myPopup: function() {
    return new myPopup(this);
  }
});

myPopup = function(jqDom) {
  var href;
  href = jqDom.attr('href');
  return $(jqDom).on('click', function() {
    window.open(href, 'popup');
    return false;
  });
};
