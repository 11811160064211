/**
 * Non-ajax GET/POST using jQuery
 * http://stackoverflow.com/questions/1149454/non-ajax-get-post-using-jquery-plugin
 */
(function($) {
  $.extend({
    postGo: function(url, params) {
      var $form = $("<form>")
        .attr("method", "post")
        .attr("action", url);
      $.each(params, function(index, param) {
        $("<input type='hidden'>")
          .attr("name", param.name)
          .attr("value", param.value)
          .appendTo($form);
      });
      $form.appendTo("body");
      $form.submit();
    }
  });
})(jQuery);
