$(function () {
  // 検索フォーム生成処理、全体2秒遅らせ
  setTimeout(function () {
    console.log("3safter_serachform_start");
    var uiView = new UIView();
    var search = new Search(uiView);
    var searchForm = new SearchForm(uiView, search);
    var keyword = new Keyword();
    _searchDataObj.init(uiView);

    if ($("input[type=radio]").size() > 0) {
      $("input[type=radio]:checked").each(function () {
        $(this).parent().addClass("be_check");
      })
      // ラジオボタンのクラス付け替え
      $("input[type=radio]").click(function () {
        var name = $(this).attr("name");
        $("input[type=radio][name=" + name + "]").each(function () {
          $(this).parent().removeClass("be_check");
        })
        $(this).parent().addClass("be_check");
      })
    }

    uiView.prepareForSegue = searchForm.prepareForSegue;
    uiView.prepareForTransition = searchForm.prepareForTransition;
    uiView.afterAppendAsyncContent = function ($asyncContent) {
      // 検索フォームが非同期で呼び出されたときの処理はココに
      // アコーディオン有効化
      search.accordionableCategory($asyncContent);
      // プルダウンデザイン整え
      search.smartablePulldown($asyncContent.find('.js_smartPulldown'));
      // エンターキー無効化
      search.cancelSubmitEventTextbox($asyncContent);
      // 一括チェック（関数内で、勤務地とそれ以外分岐）
      search.associableCheckbox($asyncContent);
      // inputのチェンジイベントに関わること    
      search.bindInputsOnChange($asyncContent);
      /*
        search.representationCategorySelectIndicator($asyncContent.find('.js_categoryRoot'));
        search.representationCategorySelectIndicator_pref($asyncContent.find('.jsSubCategoryCheck'));
      */
      // トピックスなら
      if($asyncContent.find(".js_topics_async").size() > 0){
        $asyncContent.find(".js_topics_async:checked").each(function(){
          $(this).prop("checked",false);
        })
        
        for(var n=0;n<_searchDataObj.nowData.length;n++){
          if(_searchDataObj.nowData[n]["name"] == "topicsid[]"){
            $asyncContent.find(".js_topics_async[value="+_searchDataObj.nowData[n]["value"]+"]").each(function(){
              $(this).prop("checked",true);
              common_area_search_sp.selectJudge($(this));
            })
          }
        }
      }


      // エリア選択的なものなら
      if ($asyncContent.find(".js_area_check").size() > 0) {
        common_area_search_sp.judge100($asyncContent, false);//初期チェック
        // ×ボタン押したときの挙動
        uiView.afterDismissModalSegue = function () {
          common_area_search_sp.judge100($asyncContent, false);
        }
      }

      if ($asyncContent.parents("#js_uiViewBottomFilterArea").size() > 0) {
        // トップ→詳しく条件を設定→勤務地。この時は手持ちのデータからチェック復元
        for (var _f in _searchDataObj.nowData) {
          if (_searchDataObj.nowData[_f].name == "areaid[]") {
            var _v = _searchDataObj.nowData[_f].value;
            var $chk = $asyncContent.find("[value=" + _v + "]");
            $chk.prop("checked", true);
            common_area_search_sp.areaLumpCheck_chk($chk);
            common_area_search_sp.selectJudge($chk);
          }
        }
      }
      if ($asyncContent.parents("#js_uiViewBottomFilterOccupation").size() > 0) {
        // トップ→詳しく条件を設定→職種。この時は手持ちのデータからチェック復元
        for (var _f in _searchDataObj.nowData) {
          if (_searchDataObj.nowData[_f].name == "occupation[]") {
            var _v = _searchDataObj.nowData[_f].value;
            var $chk = $asyncContent.find("[value=" + _v + "]");
            $chk.prop("checked", true);
            common_area_search_sp.areaLumpCheck_chk($chk);
            common_area_search_sp.selectJudge($chk);
          }
        }
      }
      if ($asyncContent.parents("#js_uiViewSearchOccupation").size() > 0 || $asyncContent.parents("#js_uiViewSearchArea").size() > 0) {
        console.log("removeData!");
        // トップ→職種でがさがす、または勤務地でさがす、の場合
        _searchDataObj.removeData("isWish");
        console.log(_searchDataObj.nowData);
      }

      if ($asyncContent.parents("#js_uiViewWish").size() > 0) {
        // トップ→希望条件で探す、の場合
        console.log("addData!");
        _searchDataObj.addData("isWish", 1);
        console.log(_searchDataObj.nowData);
      }




    };

    // トップページの場合、先に読みこんどく
    // （読み込んでおかないと文字列反映できない）
    if ($("#js_uiViewSearchExtra").size() > 0) {
      uiView.loadAsyncContent($("#js_uiViewSearchExtra .js_asyncContent"));
    }


    uiView.afterInitializeModalViewContents = function () {
      search.updateTotalCount(search.selectedConditions());
    };
    if (uiView.state().presentedButton) {
      searchForm.representationNavigationBarButton(uiView.state().presentedButton);
    };

    search.inputsOnChange = function (changeEvent, $input, changes) {
      _searchDataObj.updateData(changes);
      _searchDataObj.removeData("industry"); // industryがあるとgetStringでエラーになる
      var _data_u = _searchDataObj.nowData;
      var _data = search.filtering_condition(_data_u);

      if ($input.data('conditionType') !== 'should') {
        search.updateTotalCount(_data); 
      }
      //    search.representationCategorySelectIndicator($input.parents('.js_categoryRoot'));
      // 条件を表示する類のやつ
      if ($input.is('.js_representationSelectedConditions')) {
        search.representationSelectedConditions(_data);
      }

      // 特長&TOPICS
      if ($input.is('.js_topics_async')) {
        var v = $input.val();
        var s = $input.prop("checked");
        $(".js_topics[value="+v+"]").each(function(){
          $(this).prop("checked",s);
        })      
      }
      // 特長&TOPICS
      if ($input.is('.js_topics')) {
        var v = $input.val();
        var s = $input.prop("checked");
        console.log("clicked!:" + v);
        $(".js_topics_async[value="+v+"] , .js_topics[value="+v+"]").each(function(){
          console.log("changed!");
          $(this).prop("checked",s);
          common_area_search_sp.selectJudge($(this));
        })
      }


    };
    search.inputsOnAssociableChange = function (changeEvent, $input) {
      search.representationCategorySelectIndicator_pref($input.parents('.jsSubCategoryCheck'));
      if ($input.is('.js_representationSelectedConditions_pref')) {
        search.representationSelectedConditions();
      }


    };

    // 決定ボタン処理
    search.getGo($('.js_getGo'));

    var $jobSearch = $('.js_postGo');
    $jobSearch.on('click', function (event) {
      event.preventDefault();
      var params = _searchDataObj.nowData;
      var url = $(this).data('postGoUrl');
      $.postGo(url, params);
    });
  }, 500)
});
