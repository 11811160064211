$(function () {

  // 下の方の、勤務地ごとのアコーディオン【！3秒遅らせ！】
  setTimeout(function () {
    $(".js_area_accordion .js_title").click(function () {
      $(this).parents(".js_area_accordion").find(".js_content").toggle()
    })
  }, 3000)


  /***********************************
   * 貢献検索のスライダー【！3秒遅らせ！】
   ***********************************/

  setTimeout(function () {
    // スライダー初期化
    // ① 要素数を出して幅を求める
    var _num = $("#js_slider_contribution a.theme").size();
    var _wid = _num * 104; //100 = 画像あたりの幅（固定値）
    // ② 画面幅に依存する、枠の幅を求める
    var _pw = $("#js_slider_contribution").parents(".serviceJobList").width();
    // ③ 1行か、2行か
    if (_wid < _pw) {
      var _lines = 1;
    } else {
      var _lines = 2;
    }
    // ④ 1コ、または2コずつで括る
    do {
      $(".js_slider_contribution").children("a.theme:lt(" + _lines + ")").wrapAll("<li class='sliderKoma' style='width:100px;display:block;float:left;'></li>")
    } while ($(".js_slider_contribution").children("a.theme").length);
    // ⑤ 高さ決める
    $("#js_slider_contribution").parents(".serviceJobList").height(_lines * 135);

    // ⑥ 幅がオーバーしたときだけslick
    var _iw = 0// 想定スライダー幅
    if (_lines == 1) {
      _iw = _wid;
    } else {
      var _n = Math.ceil(_num / _lines); // 横個数

      _iw = _n * 104;
    }
    //  $("#js_slider_contribution").width(_iw);
    if (_iw > _pw) {
      $('.js_slider_contribution').slick({
        dots: false,
        slidesToShow: 3,
        slidesToScroll: 3,
        variableWidth: true,
        //      centerMode: true,
        //      centerPadding: "0px",
        nextArrow: "#js_con_next",
        prevArrow: "#js_con_prev",
      });
    } else {
      $("#js_slider_contribution").width(_iw);
      $("#js_slider_contribution").css("margin", "auto");
      $("#js_con_next").hide()
      $("#js_con_prev").hide()
    }
  }, 3000)


  /***********************************
   * 特集のスライダー
   ***********************************/
  setTimeout(function () {
    var $special_slider = $("#js_slider_special");
    var $special_koma = $("#js_slider_special .sliderKoma");
    if ($special_slider.size() > 0) {

      if ($special_koma.size() == 1) {
        // 特集が1件ならslickしない
        $special_koma.width($(window).width() - 40);
        $special_koma.css("margin", 10);
        $special_slider.css("height", $special_koma.height() + 20);

        $("#js_special_prev").hide();
        $("#js_special_next").hide();
      } else if ($special_koma.size() > 1) {
        // 特集が2件以上ならslickする
        $special_slider.slick({
          centerMode: true,
          centerPadding: '40px',
          // 画像下のドット（ページ送り）を表示
          dots: true,
          // ドットのclass名をつける
          dotsClass: 'slick-dots',
          nextArrow: "#js_special_next",
          prevArrow: "#js_special_prev",
          slidesToScroll: 1
        });
      }

      // コピー表示位置調整
      $('#js_slider_special .articleUnit').css("width", "100%");
      $('#js_slider_special .articleUnit').css("margin", "0 auto");
      $('#js_slider_special .articleUnit').css("padding", "15px");
    }
  }, 3000)


  /***********************************
   * 「選ぶ理由」」のやつ【3秒遅らせ】
   ***********************************/
  setTimeout(function () {
    if ($("#js_slider_selected").size() > 0) {
      $('#js_slider_selected').slick({
        centerMode: true,
        centerPadding: '40px',
        // 画像下のドット（ページ送り）を表示
        dots: true,
        // ドットのclass名をつける
        dotsClass: 'slick-dots',
        nextArrow: "#js_selected_next",
        prevArrow: "#js_selected_prev",
        slidesToScroll: 1
      });
    }
  }, 3000)

  setTimeout(function () {
    // 表示切替ブロック 【3秒遅らせ】(多分不要)
    if ($("#js_history_title").size() > 0 && $("#js_recent_title").size() > 0) {
      $("#js_history_title").click(function () {
        $("#js_history_content").removeClass("hide");
        $("#js_recent_content").addClass("hide");
        $("#js_history_title").parents(".tab").addClass("select");
        $("#js_recent_title").parents(".tab").removeClass("select");
      })
      $("#js_recent_title").click(function () {
        $("#js_history_content").addClass("hide");
        $("#js_recent_content").removeClass("hide");
        $("#js_history_title").parents(".tab").removeClass("select");
        $("#js_recent_title").parents(".tab").addClass("select");
      })
    }
  }, 3000)

});

$(function () {

  //ログイン時、検索履歴のアコーディオン【3秒遅らせ】（多分不要）
  setTimeout(function () {
    if ($("#js_history_ac").size() > 0) {
      $("#js_history_ac").myAccordion(true, function (_this) {
        _this.find(".js_title").addClass("open");
      }, function (_this) {
        _this.find(".js_title").removeClass("open");
      });
    }
  }, 3000)

  // モーダルの表示【1秒遅らせ】
  setTimeout(function () {
    if (
      $('.js_onloadModal').length == 0
    ) {
      return;
    } else if ($('#not_display_sp_top_onload_modal').val() == 1) { //ブラウザバックで戻ってきた場合
      $('.js_onloadModal').remove();
      return;
    }

    var $onloadModal = $('.js_onloadModal').myModal({}, $('#spWrapper'));
    $onloadModal.open(function () { });

    var updateModalDisplayFlg = function (callback) {
      var $form = $('.js_formUpdateModalDisplayFlg');
      $form.ajSendByForm(
        $form.attr('action')
        , function (returnData) {
          if (typeof (callback) != "function") {
            return true;
          }
          callback(returnData);
          return true;
        }
      );
    };

    $('.js_onloadModal')
      .find('.js_modalClose').on('click', function () {
        $onloadModal.hideModal();
        $('#not_display_sp_top_onload_modal').val(1);
      }).end().find('.js_modalCloseAndUpdateFlg').on('click', function () {
        $onloadModal.hideModal();
        $('#not_display_sp_top_onload_modal').val(1);
        updateModalDisplayFlg();
      }).end().find('.js_updateFlg').on('click', function (event) {
        event.preventDefault();
        $('#not_display_sp_top_onload_modal').val(1);
        var nextUrl = $(this).attr('href')
        updateModalDisplayFlg(function () {
          window.location.href = nextUrl;
        });
      });

  }, 1000)

});


$(function () {

  // 【3秒遅らせ】（用途不明。多分不要）
  setTimeout(function () {
    $('.js_articleNum').on('click', function () {
      $('.js_basis').slideToggle();
    });
  }, 3000)
});


$(function () {
  // 【3秒遅らせ】（最下部のアコーディオン）
  setTimeout(function () {
    $('.js_openLabel').on('click', function () {
      $('.js_hideSet').toggleClass('be_hide');
    });
  }, 3000)
});

$(function () {
  // 【3秒遅らせ】（転職ノウハウのアコーディオン）
  setTimeout(function () {
    $('.md_accordionTrigger_knowhow').click(function(){
      var hideContents = $(this).next('.js_acHide ');

      if($(this).hasClass('be_open')){
        $(this).removeClass('be_open');
        hideContents.slideUp();
      }else{
        $(this).addClass('be_open');
        hideContents.slideDown();
      }
      return false;
    });
  }, 3000)
});

$(function () {
  //シークレットスカウト受信通知モーダルの制御【1秒遅らせ】
  setTimeout(function () {
    var pos = 0;
    var header = $('.md_float');

    $(window).on('scroll', function () {
      if ($(this).scrollTop() > pos) {
        //上にスクロールしたとき
        header.addClass('min');

      } else {
        //下にスクロールしたとき
        header.removeClass('min');
      }
      pos = $(this).scrollTop();
    });

    header.click(function () {
      $(this).removeClass("min");
    });

    $(document).ready(function () {
      header.addClass("show");
    });

    $('.close').click(function () {
      header.removeClass("show");
      $.cookie("not_display_secret_scout_alert", "1", { path: "/" });
    });
  }, 1000)
})

// シルバーエッグ対応【遅延無し】
$(function () {
  setTimeout(function () {
    if ($('#hiddenEffectShareJobList').size() > 0) {
      $.ajax({
        type: 'POST',
        url: $('input[name=effect_share_url]').val(),
        cache: false,
        async: true,
        data: {
          offset: 0,
          'E13CT': $('#token_for_ajax').val()
        },
        dataType: 'html'
      }).done(function (data) {
        $("#hiddenEffectShareJobList").after(data);
        $("#hiddenEffectShareJobList").remove();

        var isAddSlvEg = $('input[name=is_load_slvr_egg]');
        if (isAddSlvEg.size() > 0 && isAddSlvEg.val() == 1) {
          $.ajax({
            type: 'GET',
            url: $('input[name=recomend_url]').val(),
            cache: false,
            async: true,
            jsonpCallback: 'jsonCallback',
            dataType: 'jsonp'
          }).done(function (data) {
            $('input[name=is_load_slvr_egg]').after('<div id="hiddenJobRecommendList"></div>');
            $('input[name=is_load_slvr_egg]').remove();
            createWorkDataHtml(data);
          }).fail(function (data) {
          }).always(function (data) {
          });
        }
      }).fail(function (data) {
      }).always(function (data) {
      });
    }

    if ($('#hiddenJobRecommendList').size() > 0) {
      $.ajax({
        type: 'GET',
        url: $('input[name=recomend_url]').val(),
        cache: false,
        async: true,
        jsonpCallback: 'jsonCallback',
        dataType: 'jsonp'
      }).done(function (data) {
        createWorkDataHtml(data);

      }).fail(function (data) {
      }).always(function (data) {
      });
    }

    var createWorkDataHtml = function (data) {
      var len = data['items'].length;
      var workIDS = [];
      var workCompanyNames = [];
      var occupationNames = [];
      for (var i = 0; i < len; i++) {
        workIDS.push(data['items'][i][0]['_id']);
        workCompanyNames.push(data['items'][i][0]['title']);
        occupationNames.push(data['items'][i][0]['desc']);
      }

      var json = {
        WorkIDList: workIDS,
        WorkCompanyNameList: workCompanyNames,
        OccupationNameList: occupationNames,
        'E13CT': $('#token_for_ajax').val(),
        rqid: data['rqid']
      };
      $.ajax({
        type: 'POST',
        url: $('input[name=recomend_work_data_url]').val(),
        cache: false,
        async: true,
        data: json,
        dataType: 'html'
      }).done(function (data) {
        $("#hiddenJobRecommendList").after(data);
        if($("#recommendLoading").size() > 0){
          $("#recommendLoading").hide();
        }  
        $('#top_recommend').slick({
          arrows: false,
          dots: false,
          slidesToShow: 2,
          slidesToScroll: 2,
          variableWidth: true,
          lazyLoad: 'progressive',
        })
      }).fail(function (data) {
      }).always(function (data) {
      });
    };
    
    $(document).on("click","a.recommend",function(){
    // クリックしたIDを取得する
    // http(s)://【接続先ドメイン】
        recommendClick($(this).attr('href').match(/desc_\d+/));
    });
    var recommendClick = function(descID){
        var prodid = descID[0].replace('desc_','');
        var url = $('input[name=recomend_click_url]').val().replace('prodid',prodid).replace('crefid', $('input[name=crefid]').val() );
        
        $.ajax({
            type: 'GET',
            url: url,
            cache: false,
            async: true,
            dataType: 'jsonp'
        }).done(function(data){
            //alert("success");
        }).fail(function(data){
            //alert("fail");
        }).always(function(data){
            //alert("always");
        });
    };
  }, 0)
});

