//
// common_area_search_sp
// → /dev/common_script_src/area_search_sp.jsを参照
//

var alertDone = false;

(function () {
  var randomString = function() {
    var l = 8;
    var c = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
    var cl = c.length;
    var r = "";
    for(var i=0; i<l; i++){
      r += c[Math.floor(Math.random()*cl)];
    }
    return r;
  };
  var Search = function(uiView) {

  // ここで定義された関数が、非同期で呼び出されるコンテンツが【表示されるたびに】読み込まれる

    var _this = this;
    var representationSelectedConditionsXhrQueryId = '';
    var totalCountXhrQueryId = '';

    // アコーディオンの有効化
    this.accordionableCategory = function($rootDom) {
      $rootDom.find('.js_accordion').myAccordion(true);
    };

    // 各inputのチェンジイベント系
    this.bindInputsOnChange = function($rootDom) {
        // まずは初期化する。兄弟付、
        if($rootDom.find(".js_area_check").size() > 0){
            common_area_search_sp.init($rootDom);
            // イベントのバインド
            $rootDom.on('change', ':input', function(event) {
              if($(this).attr("type") == "checkbox"){
                // リアルタイム検索、条件表示
                var changes = common_area_search_sp.areaLumpCheck_chk($(this));
                common_area_search_sp.selectJudge($(this));
                common_area_search_sp.judge100($rootDom,true);
                _this.inputsOnChange(event, $(this),changes);
              }else{
                var changes = {name : $(this).attr("name"), on_values : [$(this).val()], off_values : ["all"] }
                _this.inputsOnChange(event, $(this),changes);
              }
            });

        }else{
            common_area_search_sp.init($rootDom);
            // イベントのバインド
            $rootDom.on('change', ':input', function(event) {
              if($(this).attr("type") == "checkbox"){
                // リアルタイム検索、条件表示
                var changes = common_area_search_sp.areaLumpCheck_chk($(this));
                common_area_search_sp.selectJudge($(this));
                _this.inputsOnChange(event, $(this),changes);
              }else{
                var changes = {name : $(this).attr("name"), on_values : [$(this).val()], off_values : ["all"] }
                _this.inputsOnChange(event, $(this),changes);
              }
            });
        }

/*
         $rootDom.off("click",'.js_associableCheckbox');
         $rootDom.on("click",'.js_associableCheckbox',function(event){
          _this.inputsOnChange(event, $(this),changes);
         })
         */

        // ラジオボタンの表示切替
        $rootDom.find(".js_mdRadioSet").each(function(){
        $(this).find(".be_check").removeClass("be_check");
        $(this).find("input[type=radio]:checked").parents("label").addClass("be_check");
        })
        $rootDom.find(".js_mdRadioSet input[type=radio]").click(function(){
        $(this).parents(".js_mdRadioSet").find(".be_check").removeClass("be_check");
        $(this).parents("label").addClass("be_check");
        })
    };


    this.associableCheckbox = function($rootDom) {
      /*
      // 一括チェック
      if($rootDom.find(".js_area_check").size() > 0){
        // 勤務地系
      }else{
        // 勤務地系以外
        var $associableCheckbox = new AssociableCheckbox($rootDom);
        // 選択中判定
        $rootDom.find("input[type=checkbox]").change(function(){
            var $categoryRoot = $(this).parents(".js_categoryRoot");
            if($(this).prop("checked")){
                // チェックONのときは「選択中」表示
                $categoryRoot.find(".choosing").removeClass("js_beHide");
            }else{
                // チェックが一個もなければ非表示
                if($categoryRoot.find(":checked").size() == 0){
                    $categoryRoot.find(".choosing").addClass("js_beHide");
                }
            }
        })
      };
      */
    }

    this.cancelSubmitEventTextbox = function($rootDom) {
      $rootDom.find(':text').on('keypress', function(event) {
        if (event.keyCode && event.keyCode === 13) {
          return false;
        }
      });
    };

    // プルダウンのデザイン反映
    this.smartablePulldown = function($pulldown) {
      $pulldown
        .each(function() {
          $(this).toggleClass('empty', $(this).val() == 0);
        })
        .on('change', function(event) {
          $(this).toggleClass('empty', $(this).val() == 0);
        });
    };

    // 設定されている検索条件を取得する

    this.selectedConditions = function(requestMethod, filter) {
      requestMethod = requestMethod || 'post';
      var inputs = uiView.inputs(filter);
      var selectedConditions = {};
      if (requestMethod === 'post') {
        selectedConditions = new FormData();
        $.each(inputs, function(i, field) {
          selectedConditions.append(field.name, field.value);
        });

      }
      else if (requestMethod === 'get') {
        $.each(inputs, function(i, field) {
          if (field.value === '') {
            return;
          }
          var fieldName = field.name.replace(/\[\]/, '');
          if (selectedConditions.hasOwnProperty(fieldName)) {
            selectedConditions[fieldName] = selectedConditions[fieldName] + '_' + field.value;
          }
          else {
            selectedConditions[fieldName] = field.value;
          }
        });
      }
      return selectedConditions;
    };

    this.filtering_condition = function(_data,requestMethod, filter) {

      for(var _i in _data){
        if(_data[_i].name == "areaid" || _data[_i].name == "industry" || _data[_i].name == "occupation"){
          _data.splice(_i,1);
        }
      }

      requestMethod = requestMethod || 'post';
      var inputs = _data;
      var selectedConditions = {};
      if (requestMethod === 'post') {
        selectedConditions = new FormData();
        $.each(inputs, function(i, field) {
          selectedConditions.append(field.name, field.value);
        });

      }
      else if (requestMethod === 'get') {
        $.each(inputs, function(i, field) {
          if (field.value === '' || field.name == undefined) {
            return;
          }
          var fieldName = field.name.replace(/\[\]/, '');
          if (selectedConditions.hasOwnProperty(fieldName)) {
            selectedConditions[fieldName] = selectedConditions[fieldName] + '_' + field.value;
          }
          else {
            selectedConditions[fieldName] = field.value;
          }
        });
      }

      return selectedConditions;
    };

    this.inputsOnChange = function() {};

    // 設定された条件の表示（職種、勤務地、業種）
    this.representationSelectedConditions = function(_data) {
      representationSelectedConditionsXhrQueryId = randomString();
      var data = _data;
      data.append('queryId', representationSelectedConditionsXhrQueryId);
      data.append(TOKEN_FOR_AJAX_NAME, TOKEN_FOR_AJAX);
      $.ajax({
        url: $('#js_apiEndPoint').data('indexToString'),
        data: data,
        method: 'POST',
        dataType: 'json',
        processData: false,
        contentType: false,
        success: function(data, textStatus, jqXHR) {
          if (data.queryId != representationSelectedConditionsXhrQueryId) {
            return;
          }
          var $selectedOccupation = $('.js_selectedConditions.js_selectedConditions--type-occupation');
          var $selectedArea = $('.js_selectedConditions.js_selectedConditions--type-area');
          var $selectedIndustry = $('.js_selectedConditions.js_selectedConditions--type-industry');
          var $selectedTopics = $('.js_selectedConditions.js_selectedConditions--type-topics');
          $selectedOccupation.myAdaptToDom(data.occupation.length === 0 || (data.occupation.length == 1 && data.occupation[0] == '') ? ['---'] : data.occupation);
          if(data.occupation.length === 0 || (data.occupation.length == 1 && data.occupation[0] == '')){ $selectedOccupation.find(".data").removeClass("data"); }// 太字にならないように
          $selectedArea.myAdaptToDom(data.area.length === 0 ? ['---'] : data.area);
          if(data.area.length === 0){ $selectedArea.find(".data").removeClass("data"); }// 太字にならないように
          $selectedIndustry.myAdaptToDom(data.industry.length === 0 ? ['---'] : data.industry);
          if(data.industry.length === 0){ $selectedIndustry.find(".data").removeClass("data"); }// 太字にならないように

          // ↓検索リニューアル用の処理 ↓ //
          if(data.topics.length === 0){ $(".js_append_topics_cls").remove(); }else{
            _this.setCheckedTopics(data,_data);
          }
          // ↓検索リニューアルじゃない時用の処理 ↓ //
//          $selectedTopics.myAdaptToDom(data.topics.length === 0 ? ['---'] : data.topics);
//          if(data.topics.length === 0){ $selectedTopics.find(".data").removeClass("data"); }
        }
      });
    };

    // 検索リニューアル用、チェックした選択肢を下部にappendする
    this.setCheckedTopics = function(_txt_data,_form_data){
      // 追加した分を削除
      $(".js_append_topics_cls").remove();

      // 現在設定されている条件を拾ってくる
      var topics_append = '<div id="js_append_topics" class="js_append_topics_cls"><div class="md_checkUnit_Sevral">';
      var _count = 0
      for(var _i=0;_i<_txt_data.topics.length;_i++){
        if(_count>0 && _count%2==0){
          topics_append += '</div><div class="md_checkUnit_Sevral">';
        }
        var vl = _form_data.getAll("topicsid[]")[_i];
        var st = "";

        // メインの8種に存在していたら処理をスキップ
        if($(".js_topics[value="+vl+"]").size() > 0){
          continue;
        }
        if($(".js_topics_async[value="+vl+"]").size() > 0){
          st = $(".js_topics_async[value="+vl+"]").parents("label").text();
          st = st.replace(/\([0-9]+件\)/i, '');
        }else{
          st = _txt_data.topics[_i];
        }
        topics_append += '<input type="checkbox" class="js_filter js_topics" name="topicsid[]" value="'+vl+'" id="add_topic_'+vl+'" checked><label for="add_topic_'+vl+'">'+st+'</label>';
        _count += 1;
      }
      topics_append += '</div></div>';
      topics_append = topics_append.replace('<div class="md_checkUnit_Sevral"></div>','');
      $("#top_accordion .md_checkUnit").each(function(){
        $(this).append(topics_append);
      })
    }


    this.representationCategorySelectIndicator = function($categoryRoot) {
      if ($categoryRoot.length > 1) {
        return $categoryRoot.each(function() {_this.representationCategorySelectIndicator($(this))});
      }
      /*
      var $categorySelectIndicator = $categoryRoot.find('.js_categorySelectIndicator');
      $categorySelectIndicator.toggleClass('js_beHide', $categoryRoot.find(':checked').length < 1);
      */
    };

    this.representationCategorySelectIndicator_pref = function($categoryRoot) {
    };

    this.updateTotalCount = function(_data) {
      /*
      var $filterKeyword = uiView.$findCurrentActiveGroup('.js_filterKeyword');
      if ($filterKeyword.length > 0 && $filterKeyword.val() !== '') {
        $('.js_totalCount').addClass('js_beHide');
        return;
      }
      */
      $('.js_totalCount').removeClass('js_beHide');
      var $results = $('.js_results');
      var $loadingIndicator = $('.js_loading');
      $loadingIndicator
        .addClass('js_animable')
        .removeClass('js_beHide');
      var data = _data;
      $results.addClass('js_beHide');
      setTimeout(function(){
          totalCountXhrQueryId = randomString();
          data.append('queryId', totalCountXhrQueryId);
          data.append(TOKEN_FOR_AJAX_NAME, TOKEN_FOR_AJAX);
          $.ajax({
            url: $('#js_apiEndPoint').data('totalCount'),
            data: data,
            method: 'POST',
            dataType: 'json',
            processData: false,
            contentType: false,
            success: function(data, textStatus, jqXHR) {
              if (data.queryId != totalCountXhrQueryId) {
                return;
              }
              $results
                .text(data.totalCount)
                .removeClass('js_beHide');
              $loadingIndicator
                .addClass('js_beHide')
                .removeClass('js_animable');
            },always : function(data){
            }
          });
      },500)
    };
    this.isRequireParamExists = function(params, requireParam) {
      if (requireParam === undefined) {
        return true;
      }
      requireParam = requireParam.split(',');
      var isRequireParamExists = false
      $.each(params, function(key, value) {
        if ($.inArray(key, requireParam) !== -1) {
          isRequireParamExists = true;
        }
      });
      return isRequireParamExists;
    };

    // 決定ボタンをクリックしたときの処理
    this.getGo = function($button) {
      $button.on('click', function(event) {
// TENSHOKU_BUGMANAGEMENT-1074 対応
/*
        if($(this).hasClass("js_over100")){
          alert("勤務地の指定は最大100個までです。\n選択している勤務地を100個以下に減らしてください。");
          $(this).addClass("md_btn--disable");
          return false;
        }
*/
        event.preventDefault();
        var selectedConditions = _this.filtering_condition(_searchDataObj.nowData,'get');
        if (_this.isRequireParamExists(selectedConditions, $(this).data('requireParam')) === false) {
          alert($(this).data('validationMessage'));
          return;
        }

        // reproイベントトラック
        var queryRpr = location.search.substr(1);
        var urlRpr = location.href;
        if((urlRpr.match(/search\/search_list/)) && !(queryRpr.match(/aroute=12/))) {
        }

        var goGetUrl = $(this).data('getGoUrl');
        var leadChar = /\?/.test(goGetUrl) ? '&' : '?';
        location.href = goGetUrl + leadChar + $.param(selectedConditions);
      });
    };







  };
  window.Search = Search;
})();




// 勤務地が100オーバーした時の挙動をここに定義
common_area_search_sp.whenOver100 = function($dom,showAlert){
// TENSHOKU_BUGMANAGEMENT-1074 対応
/*
    if(alertDone == undefined || alertDone == false){
        if(showAlert){
            alert("勤務地の指定は最大100個までです。\n選択している勤務地を100個以下に減らしてください。");
        }
        $dom.parents(".js_uiView").find(".js_decide").addClass("md_btn--disable").addClass("js_disabled").attr("data-errorMessage","勤務地の指定は最大100個までです。\n選択している勤務地を100個以下に減らしてください。");
        $(".js_getGo").addClass("md_btn--disable").addClass("js_over100");

        if($dom.parents(".js_uiView").find(".close.js_uiViewDismissModalSegue").size() > 0){

          $dom.parents(".js_uiView").find(".close.js_uiViewDismissModalSegue").addClass("js_over100").addClass("js_disabled");
        }

        // トップの、「職種を追加」ボタン
        if($("#js_nextOccupation").size() > 0){
          $("#js_nextOccupation").addClass("md_btn--disable").removeClass("js_uiViewSegue").addClass("js_over100");
        }
        // トップの、「さらに条件を設定～」ボタン
        if($("#js_nextExtra").size() > 0){
          $("#js_nextExtra").addClass("md_btn--disable").removeClass("js_uiViewSegue").addClass("js_over100");
        }
        alertDone = true;
    }
*/
};

common_area_search_sp.whenUnder100 = function($dom,showAlert){
    $dom.parents(".js_uiView").find(".js_decide").removeClass("md_btn--disable").removeClass("js_disabled");
    $(".js_getGo").removeClass("md_btn--disable").removeClass("js_over100");
    // トップの、「職種を追加」ボタン
    if($("#js_nextOccupation").size() > 0){
      $("#js_nextOccupation").removeClass("md_btn--disable").addClass("js_uiViewSegue").removeClass("js_over100");
    }
    // トップの、「さらに条件を設定～」ボタン
    if($("#js_nextExtra").size() > 0){
      $("#js_nextExtra").removeClass("md_btn--disable").addClass("js_uiViewSegue").removeClass("js_over100");
    }
    if($dom.parents(".js_uiView").find(".close.js_uiViewDismissModalSegue").size() > 0){
     $dom.parents(".js_uiView").find(".close.js_uiViewDismissModalSegue").removeClass("js_over100").removeClass("js_disabled");
    }

    alertDone = false;
    if($("#over100Alert").size() > 0){
      $("#over100Alert").hide();
    }
};

$(function(){
// TENSHOKU_BUGMANAGEMENT-1074 対応
/*
  if($("#js_nextOccupation").size() > 0){
    $("#js_nextOccupation").click(function(){
      if($(this).hasClass("js_over100")){
        alert("勤務地の指定は最大100個までです。\n選択している勤務地を100個以下に減らしてください。");
      }
    })
  }
  if($("#js_nextExtra").size() > 0){
    $("#js_nextExtra").click(function(){
      if($(this).hasClass("js_over100")){
        alert("勤務地の指定は最大100個までです。\n選択している勤務地を100個以下に減らしてください。");
      }
    })
  }
  if($(".close.js_uiViewDismissModalSegue").size() > 0){
    $(".close.js_uiViewDismissModalSegue").click(function(){
      if($(this).hasClass("js_over100")){
        alert("勤務地の指定は最大100個までです。\n選択している勤務地を100個以下に減らしてください。");
      }
    })
  }
*/

})
