(function () {
  var SearchForm = function(uiView, search) {
    var _this = this;
    this.representationNavigationBarButton = function(presentationButton) {
      uiView.$navigationBars.find('.md_btn').addClass('js_beHide');
      if (presentationButton === 'occupation') {
        uiView.$navigationBars.find('.js_addOccupation').removeClass('js_beHide');
      } else if (presentationButton === 'area') {
        uiView.$navigationBars.find('.js_addArea').removeClass('js_beHide');
      } else if (presentationButton === 'extra') {
        uiView.$navigationBars.find('.js_addExtra').removeClass('js_beHide');
      }
      uiView.extendCurrentViewState({ presentedButton: presentationButton });
    };

    this.prepareForTransition = function(currentViewState, prevViewState, transitionDirection) {
      var currentViewIdentification = currentViewState.destinationViewIdentification;
      var prevViewIdentification = prevViewState.destinationViewIdentification;
      if (transitionDirection === 'forward') {
        if (prevViewIdentification === 'searchIndex') {
          if (currentViewIdentification === 'searchOccupation') {
            _this.representationNavigationBarButton('area');
          } else if (currentViewIdentification === 'searchArea') {
            _this.representationNavigationBarButton('occupation');
          }
        } else if (prevViewIdentification === 'searchOccupation') {
          _this.representationNavigationBarButton('extra');
        } else if (prevViewIdentification === 'searchArea') {
          _this.representationNavigationBarButton('extra');
        } else if (prevViewIdentification === 'root') {
          if (currentViewIdentification === 'searchOccupation') {
            _this.representationNavigationBarButton('area');
          } else if (currentViewIdentification === 'searchArea') {
            _this.representationNavigationBarButton('occupation');
          }
        }
      } if (transitionDirection === 'back') {
        if (prevViewIdentification === 'searchExtra') {
          _this.representationNavigationBarButton('extra');
        } else if (prevViewIdentification === 'searchOccupation') {
          _this.representationNavigationBarButton('occupation');
        } else if (prevViewIdentification === 'searchArea') {
          _this.representationNavigationBarButton('area');
        }
      }
    };

    this.prepareForSegue = function($segue) {
      var selectedConditions = search.filtering_condition(_searchDataObj.nowData,'get');
      if (search.isRequireParamExists(selectedConditions, $segue.data('requireParam')) === false) {
        alert($segue.data('validationMessage'));
        return 'cancelSegue';
      }
    };

  };
window.SearchForm = SearchForm;
})();
