var appSdkUtil = {

  isApp : function(){
    var ua = navigator.userAgent.toLowerCase();
    if(ua.indexOf('enapp') <= -1){
      return false;
    }else{
      return true;
    }
  },


  reproEventTrack : function(_name,_option,_funcName){
      if(typeof repro !== "undefined" && appSdkUtil.isApp() && typeof repro.track == "function"){
        if(typeof _option === "undefined"){
          _option = {};
        }else{
            _option = JSON.parse(_option);
        }
        if(typeof _funcName === "undefined"){
          _funcName = "track";
        }
        if(_funcName === "trackSearch"){
          repro[_funcName]();
        }else if(_funcName === "trackCompleteRegistration") {
          repro[_funcName]();
        }else if(_funcName === "trackViewContent"){
          repro[_funcName](_option['contentID'],{extras:_option});
        }else{
          repro[_funcName](_name,_option);
        }
      }
  },


  appsflyerEventTrack : function(_eventName,_eventValue){
  },

  logEvent : function(name, params) {
    if (!appSdkUtil.isApp() || !name) {
        return;
    }

    if(typeof params === "undefined"){
        params = {};
    } else {
        params = JSON.parse(params);
    }

    if (window.AnalyticsWebInterface) {
        // Call Android interface
        window.AnalyticsWebInterface.logEvent(name, JSON.stringify(params));
    } else if (window.webkit
        && window.webkit.messageHandlers
        && window.webkit.messageHandlers.firebase) {
        // Call iOS interface
        var message = {
            command: 'logEvent',
            name: name,
            parameters: params
        };
        window.webkit.messageHandlers.firebase.postMessage(message);
    } else {
        // No Android or iOS interface found
        console.log("No native APIs found.");
    }
  },

  setUserProperty : function(name, value) {
    if (!appSdkUtil.isApp() || !name || !value) {
        return;
    }

    if (window.AnalyticsWebInterface) {
        // Call Android interface
        window.AnalyticsWebInterface.setUserProperty(name, value);
    } else if (window.webkit
        && window.webkit.messageHandlers
        && window.webkit.messageHandlers.firebase) {
        // Call iOS interface
        var message = {
            command: 'setUserProperty',
            name: name,
            value: value
        };
        window.webkit.messageHandlers.firebase.postMessage(message);
    } else {
        // No Android or iOS interface found
        console.log("No native APIs found.");
    }
  },
}