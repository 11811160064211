/**
* $.myModalまたは$.myModalByAjax でモーダルオブジェクトを作成し、オブジェクト単位でopen/closeなどの操作を行う。
* @class myModal
**/


$.fn.extend({
/**
* モーダルを作成します。（内部HTMLをモーダル化）
* @method $(モーダル表示用DOM).myModal
* @param option {ハッシュ} オプションもろもろ指定（暫定）
* @param wrapper {DOM} 最も外側を囲っているDOM。
* @return モーダルオブジェクト。
* @example 
* .js:<br><code>
* var modal1 = $('#modal').myModal({},$('#spWrapper'));</code><br>
*<br>
* .html:<br>
* <code>
* &lt;div id='modal' class='myModalHide'&gt;<br>
* 　&lt;div class='myModalInner'&gt;<br>
* 　　&lt;!--- モーダルの中身 ---&gt;<br>
* 　&lt;/div&gt;<br>
* &lt;/div&gt;<br>
* </code>
*
**/
    myModal: function(option,wrapper){
        if(option == undefined){
            option = {};
        }
        var _option = option;
        _option['mode']= 'dom';
        _option['_window'] = this;
		_option['wrapper'] = wrapper;
        return new myModalControl(_option);

    },
/**
* モーダルを作成します。（外部ファイル出力をモーダルに表示）
* @method $(モーダル表示用DOM).myModalByAjax
* @param url {文字列} 外部ファイルのURL
* @param data {ハッシュ} 外部ファイルに渡すデータ。POSTで送信
* @param option {ハッシュ} オプションもろもろ指定（暫定）
* @param wrapper {DOM} 最も外側を囲っているDOM。
* @return モーダルオブジェクト。
* @example 
* .js:<br>
* <code>var modal2= $('#modalByAjax').myModal('layer/sample.html',{name : 'myName'},{},$('#spWrapper'));</code><br>
*<br>
* .html:<br>
* <code>
* &lt;div id='modalByAjax' class='myModalHide'&gt;&lt;/div&gt;
* </code>
*
*
**/
    myModalByAjax: function(url,data,option,wrapper){
        if(option == undefined){
            option = {};
        }
        var _option = option;
        _option['mode'] = 'ajax';
        _option['url'] = url;
        _option['_window'] = this;
        _option['data'] = data;
		_option['wrapper'] = wrapper;
        return new myModalControl(_option);
    }
})

/**
* $.myModalまたは$.myModalByAjax で作成したオブジェクトを操作する。
* @class myModalControl
**/


var myModalControl = function(_option){

	if($("#modalWindow").size() > 0 && $("#js_modalScreen").size() == 0){
		$("body").prepend("<div id='js_modalScreen' class='js_modalHide'></div>");
	}

	var thisObj = this;
    this.mode = _option.mode;
    this.url = _option.url;
    this._window = _option._window;
    this.data = _option.data;
	this._box = this._window.find('.js_modalInner');
	this._wrapper = _option.wrapper;
	this.wid = $(window).width() - 20;
	this.savedData = {};
	$("#js_modalScreen").click(function(){
		thisObj.hideModal();
		if($('#modalWindow').find('.js_closeAndReload').length > 0){
			parent.location.reload();
		}
	});
		

    this.debug = function(){
        alert(this.mode);
    };

	$(window).resize(function(){
		thisObj.wid = $(window).width() - 20;
		thisObj._box.outerWidth(thisObj.wid);
	});
	
/**
* モーダルを表示します。
* @method open
* @param callback {関数} 表示完了後の処理
* @example 
* <code>modal1.open(function(){alert('完了')})</code><br>
**/
    this.open = function(callback){
        if(this.mode == 'dom'){
            // domの要素から拾ってきてオープン
			this.showModal();
			callback();
        }else{
            // ajaxからロードしてオープン
			this.ajaxLoadContents(this.url,this.data,callback);
        }
	};

	this.ajaxLoadContents = function(url,postData,callback){
		var thisObj = this;
		$.ajax({
			url: url,
			data: postData
		}).done(function(data){
			thisObj._box.html(data);
			thisObj.showModal();
			callback();
		}).fail(function(data){
		}).always(function(data){
		});
	};
/**
* モーダルを非表示にします。
* @method hideModal
* @example 
* <code>modal1.hideModal()</code><br>
**/
	this.hideModal = function(){
		this._window.addClass('js_modalHide');
		$("#js_modalScreen").addClass('js_modalHide');
		this._wrapper.removeClass("jqOpacity_modal");
	};

    this.showModal = function(){
		this._window.removeClass('js_modalHide');
		$("#js_modalScreen").removeClass('js_modalHide');
		if(this.wid < 200){
			this.wid = 200;
		}
		this._box.outerWidth(this.wid);
		this._wrapper.addClass("jqOpacity_modal	");
		var t = $(window).height() - this._box.height();
		if(t > 0){
			this._box.css("top",t/2);
		}else{
			this._box.css("top",10);
		}
    };

/**
* モーダル内のフォームからデータを取得し、配列として返します。
* @method getData
* @return data{配列} フォーム各要素のname=>valueの形で返す
* @example 
* <code>modal1.hideModal()</code><br>
**/

	this.saveData = function(){
		var formData = this._window.find("form").serializeArray();
		this._savedData = formData;
	};
	
	this.getData = function(){
		var formData = this._window.find("form").serializeArray();
		return formData;
	};
/*,
	hideModal:function(modal,eventTarget,jqBasePage){
		//eventTargetが渡されてこない、
		//または渡されてきてもそれがモーダル外であれば、非表示化実行
		if(!eventTarget 
			|| !$.contains(modal.get(0),eventTarget)){
			modal.addClass('hide');
			this._basePageShow(jqBasePage);
		}
	},
	mirror:{
		run:function(from,to){
			this._mirrorRadio(from.jqRadio,to.jqRadio);
			this._mirrorCheckbox(from.jqCheckbox,to.jqCheckbox);
		},
		_mirrorRadio:function(jqFromRadio,jqToRadio){
			//toのチェックをすべて外してから、必要な要素にチェックをつける
			jqToRadio.filter(':checked').prop('checked',false);
			var jqFromRadioChecked = jqFromRadio.filter(':checked');
			if(!jqFromRadioChecked.length){ //TODOこの例外処理は不要？どうせ次eachなので
				return false;
			}
			jqFromRadioChecked.each(function(){
				var element = {
					name : $(this).prop('name'),
					val : $(this).val()
				};
				jqToRadio.filter('[name="'+element.name+'"][value='+element.val+']').prop('checked',true);
			});
		},
		_mirrorCheckbox:function(jqFromCheckbox,jqToCheckbox){
			//toのチェックをすべて外してから、必要な要素にチェックをつける
			jqToCheckbox.filter(':checked').prop('checked',false);
			var jqFromCheckboxChecked = jqFromCheckbox.filter(':checked');
			if(!jqFromCheckboxChecked.length){
				return false;
			}
			jqFromCheckboxChecked.each(function(){
				var element = {
					name : $(this).prop('name'),
					val : $(this).val()
				};
				jqToCheckbox.filter('[name="'+element.name+'"][value='+element.val+']').prop('checked',true);
			});
		}
	},
	tab:{
		init:function(jqTab){
			jqTab
				.hide()
				.first().show();
		},
		next:function(jqCurrentTab){
			if(jqCurrentTab.next('div .modalTab').length){
				jqCurrentTab
					.hide()
					.next('div .modalTab').show();
			}
		},
		prev:function(jqCurrentTab){
			if(jqCurrentTab.prev('div .modalTab').length){
				jqCurrentTab
					.hide()
					.prev('div .modalTab').show();
			}
		}
	},
	_basePageHide:function(jqClickableOnBasePage){
		//対象OS機種はバグ回避のため、モーダルの下のタップ可能要素を非表示にする
		if(this._validateBasePageHideTargetOS()){
			jqClickableOnBasePage.addClass('hide');
		}
	},
	_basePageShow:function(jqClickableOnBasePage){
		if(this._validateBasePageHideTargetOS()){
			jqClickableOnBasePage.removeClass('hide');
		}
	},
	_validateBasePageHideTargetOS:function(){
		//android2.*系が対象
		return ( window.navigator.userAgent.toLowerCase().indexOf('android 2.') != -1);
	},
	_scrollForBugFix:function(jqWin){
		//モーダル上のaタグがクリックできない件を修正するため、すこしだけ画面をスクロールさせる
		if(this._validateBasePageHideTargetOS()){
			var scrollTop = jqWin.scrollTop();
			if(scrollTop == 0){
				jqWin.scrollTop(scrollTop+10);
			}
		}
		
	}
*/
};

var copyText = function(jqTarget,formObj){
	var jqCheckdElement = formObj.jqCheckbox.add(formObj.jqRadio).filter(':checked');
	var textArray=[];
	jqCheckdElement.each(function(){
		//ここの値の取り方はhtmlによって変化するはず
		textArray.push(this.nextSibling.nodeValue);
	});
	jqTarget.text(textArray.join(' / '));
};
function makeModal(option){
    
}

