var SearchData = function(){
  var _this = this;
  this.nowData = [];
  this.init = function(_uiview){
    _this.nowData = _uiview.inputs();
    
    // キーワードだけデコードしておく
    for(var _i  in _this.nowData){
      if(_this.nowData[_i].name == "keywordtext" && _this.nowData[_i].value != ""){
        var _tmp = _this.nowData[_i].value.replace(/\+/g, ' ');
        _this.nowData[_i].value = decodeURIComponent(_tmp);
      }
    }
  }

  // PCのリアルタイムカウント用に作る関数
  this.init_pc = function(_data){

    _this.nowData = _data;
    
    // キーワードだけデコードしておく
    for(var _i  in _this.nowData){
      if(_this.nowData[_i].name == "keywordtext" && _this.nowData[_i].value != ""){
        var _tmp = _this.nowData[_i].value.replace(/\+/g, ' ');
        _this.nowData[_i].value = decodeURIComponent(_tmp);
      }
    }
  }

  this.replaceData = function(_data){
    _this.nowData = _data;
  }
  
  this.removeData = function(_name){
    // name の名前のデータを全削除
    var _tmp = _this.nowData.filter(function(element){
      if(element.name == _name){
        return false;
      }else{
        return true;
      }
    })
    _this.replaceData(_tmp);
    return _this.nowData;
  }
  
  this.addData = function(_name,_value){
    // 同値が無ければ、追加
    var _flg = true;
    for(_i in _this.nowData){
      if(_this.nowData[_i].name == _n && _this.nowData[_i].value == _v ){
        _flg = false;
      }
    }
    if(_flg == true){
      _this.nowData.push({name : _name , value : _value});
    }
    return _this.nowData;
  }
  
  this.updateData = function(_data){
    // _data = [{name : "名前", on_values : "ONする値", off_values : "OFFする値"}]の形

    
    // off_values、on_valuesの削除
    if(_data.off_values[0] == "all"){
      var _name = _data.name; //この名前のやつを全削除する
      var _tmp = _this.nowData.filter(function(element){
          if(element.name == _name){
            return false;
          }else{
            return true;
          }
      })
      
    }else{
      var _remove = _data.off_values.concat(_data.on_values);
      var _tmp = _this.nowData.filter(function(element){
        var _flg = true; // 照合用
        for(var _e in _remove){
          var _n = _data.name;
          var _v = _remove[_e];
          if(element.name == _n && element.value == _v){
            _flg = false; // かぶりあり！
          }
        }
        if(_flg == true){
          return element;
        }else{
          return false;
        }
      })
    }
    
    // on_valuesの追加
    for(var _m in _data.on_values){
      var _n = _data.name;
      _tmp.push({name : _n , value : _data.on_values[_m]});
    }
    // nowDataの更新
    _this.replaceData(_tmp);
    // return
    return _this.nowData;
        
  }
  this.parse = function(text, sep, eq, isDecode) {
    text = text || location.search.substr(1);
    sep = sep || '&';
    eq = eq || '=';
    var decode = (isDecode) ? decodeURIComponent : function(a) { return a; };
    var _tmp = text.split(sep);
    var _rtn = [];
    for(var _i in _tmp){
      var pair = _tmp[_i].split(eq);
      _rtn.push({name : pair[0], value :  decode(pair[1])});
    }
    return _rtn;
  };
  this.stringify = function(value, sep, eq, isEncode) {
    sep = sep || '&';
    eq = eq || '=';
    var encode = (isEncode) ? encodeURIComponent : function(a) { return a; };
    return Object.keys(value).map(function(key) {
      return key + eq + encode(value[key]);
    }).join(sep);
  };
}

var _searchDataObj = new SearchData();