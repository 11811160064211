(function () {
  var Keyword = function() {
    var keywordSearchByUrl = function(keywordText, permitted_uri_chars, _PK){
      var inputWord = keywordText;
      var permitted_reg = new RegExp("^["+permitted_uri_chars+"]+$","i");
      if (!inputWord.match(permitted_reg)) {
        return true;
      }
      // ドットをURLに入れると階層移動を表してしまう場合がある
      if (inputWord.match(/[\x2E]/)) {
        return true;
      }
      // IISで二重エスケープされた文字が拒否(Error404.11)されるためこれも制的URLには使えないものとする
      if (inputWord.match(/%[0-9a-f]{2}/i)) {
        return true;
      }
      //===================================================
      // 業務的に静的URLとして扱うかどうかの判定。
      // 2単語以上ある場合NG
      // 含められない場合静的化しない場合の通常のフォームでのSUBMITに任せる。
      //===================================================
      if (inputWord.match(/[\x20\u00A0\u3000]/)) {
        return true;
      }

      // 100文字制限:100文字以降は切り捨て
      if (inputWord.length > 100) {
        inputWord = inputWord.substr(0,100);
      }
      //===================================================
      // 静的化URLにGETアクセス。
      //===================================================
      var encodedWord = encodeURI(inputWord);
      var gotoURL = '/keyword/' + encodedWord + "/";
      if($("#areaURL").size() > 0){
        gotoURL = $("#areaURL").val() + gotoURL;
      }
      if(_PK != ""){
        gotoURL += _PK;
      }

      gotoURL = gotoURL.replace(/\/\//g,"/");
      location.href = gotoURL;
      return false;
    }
    $(document).on('click', '#keywordSearch_btn', function(event){
      if(keywordSearchByUrl($("#keywordSearch_input").val(),$("#permitted_uri_chars").val(),$("#PK").val())){
        $("#keywordSearch_form").submit();
      }
      event.preventDefault();
      event.stopImmediatePropagation();
      return false;
    });

    $(document).on('click', '#keywordSearch_btn_top', function(event){
      if(keywordSearchByUrl($("#keywordSearch_input_top").val(),$("#permitted_uri_chars_top").val(),$("#PK").val())){
        $("#keywordSearch_form_top").submit();
      }
      event.preventDefault();
      event.stopImmediatePropagation();
      return false;
    });

  };
  window.Keyword = Keyword;
})();

