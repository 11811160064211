// [ス]エリア検索用の諸々
// [ス]検索、[ス]会員登録、[ス]WEB履歴書など

var common_area_search_sp = {
  alertDone : false,
  init : function($rootDOM){
      var _this = this;
  },
  
  
  areaLumpCheck_chk : function($chkbox){
    
    // 変更結果をreturnする
    var changes = {"name" : $chkbox.attr("name") ,"on_values" : [] , "off_values" : []};
    var chkFlg = $chkbox.prop("checked");
    
    
    if($chkbox.hasClass("js_area_check") || $chkbox.hasClass("js_pref_check") || $chkbox.hasClass("js_city_check") || $chkbox.hasClass("js_siku_check")){
      
      var $parents //親
      var $grandp // 祖父
      var $ggrandp // 曽祖父
      var $children //子・孫・ひ孫
      var $siblings // 兄弟
      var changes_tmp = [];
      var changes_tmp_off = [];
      var changes_tmp_on  = [];

      $g_granp    = $chkbox.parents(".js_area_div").find(".js_area_check"); // エリアひいおじいちゃん
      $granp      = $chkbox.parents(".js_pref_div").find(".js_pref_check"); // おじいちゃん都道府県
      $granp_sib  = $chkbox.parents(".js_area_div").find(".js_pref_check"); // おじいちゃんとその兄弟たち
      $parent     = $chkbox.parents(".js_city_div").find(".js_city_check"); // お父さん政令指定都市
      $parent_sib = $chkbox.parents(".js_pref_div").find(".js_city_check,.js_shigun_check"); // お父さんの兄弟たち
      $sibling    = $chkbox.parents(".js_city_div").find(".js_ku"); // 兄弟たち
      

      if($chkbox.hasClass("js_area_check")){
        $children = $chkbox.parents(".js_area_div").find("input[type=checkbox]").not(".js_area_check");
      }else if($chkbox.hasClass("js_pref_check")){
        $children = $chkbox.parents(".js_pref_div").find("input[type=checkbox]").not(".js_pref_check");
      }else if($chkbox.hasClass("js_city_check")){
        $children = $chkbox.parents(".js_city_div").find("input[type=checkbox]").not(".js_city_check");
      }else if($chkbox.hasClass("js_shigun_check")){
        $children = null;
      }else if($chkbox.hasClass("js_ku")){
        $children = null;
      }
      
      
      // 変更内容を保持①
      if(chkFlg){
        changes_tmp_on.push($chkbox.val());
      }else{
        changes_tmp_off.push($chkbox.val());
      }
      
      if($children !== null){
        // 子・孫チェックは完全連動
        $children.prop("checked",chkFlg);
        
        // 変更内容を保持②（外す時は、省略可）
        $children.each(function(){
          changes_tmp_off.push($(this).val());
        })
      }
      if(!chkFlg){
        if($parent.size() > 0){
            $parent.prop("checked",false); // 親
            changes_tmp_off.push($parent.val());
            $sibling.filter(":checked").each(function(){
              changes_tmp_on.push($(this).val());
            })
        }
        if($granp.size() > 0){
                $granp.prop("checked",false); // 祖父
                changes_tmp_off.push($granp.val());
                $parent_sib.filter(":checked").each(function(){
                  changes_tmp_on.push($(this).val());
                })
        }              
        if($g_granp.size() > 0){
                    $g_granp.prop("checked",false); // 親\曾祖父
                    changes_tmp_off.push($g_granp.val());
                    $granp_sib.filter(":checked").each(function(){
                      changes_tmp_on.push($(this).val());
                    })
        }

      }else{
        if($parent.size() > 0){
          // ① 兄弟が全チェックなら、親にチェック
          if($sibling.size() == $sibling.filter(":checked").size()){
            $parent.prop("checked",true);
            changes_tmp_on = changes_tmp_on.filter(function(a){
              return a !== $chkbox.val();
            })
            changes_tmp_on.push($parent.val());
            $sibling.filter(":checked").each(function(){
              changes_tmp_off.push($(this).val());
            })
          }
        }
            // ② ①かつ、それで親兄弟が全チェックなら、祖父にチェック
        if($granp.size() > 0){
          if($parent_sib.size() == $parent_sib.filter(":checked").size()){
            $granp.prop("checked",true); // 曾祖父
            changes_tmp_on.pop();
            changes_tmp_on.push($granp.val());
            $parent_sib.filter(":checked").each(function(){
              changes_tmp_off.push($(this).val());
            })
          }
        }

       // ③ ②かつ、それで祖父が全チェックなら、曽祖父にチェック
       if($g_granp.size() > 0){
         if($granp_sib.size() == $granp_sib.filter(":checked").size()){
           $g_granp.prop("checked",true); // 曾祖父
           changes_tmp_on.pop();
           changes_tmp_on.push($g_granp.val());
           $granp_sib.filter(":checked").each(function(){
             changes_tmp_off.push($(this).val());
           })
          }
        }
        
      }
      
      // 変更内容保持した奴をリターン
      changes.on_values = changes_tmp_on;
      changes.off_values = changes_tmp_off;
    }else{
      // 一括チェックを考慮しなくていい人たち
      if(chkFlg == true){
        changes.on_values = [$chkbox.val()];
      }else{
        changes.off_values = [$chkbox.val()];
      }
    }
    return changes;
   
  },
  
  areaLumpCheck : function($rootDom,selFlg){
    
    if(selFlg == null){
      selFlg = true;
    }
    
    var _this = this;
    $rootDom.on("click",".js_area_check, .js_pref_check, .js_siku_check, .js_city_check",function(){
      _this.areaLumpCheck_chk($(this));
      if(selFlg == true){
        _this.selectJudge($(this));
      }
      _this.judge100($rootDom,true);
    })

    // 変数束ねる用の兄弟定義
    $rootDom.find(".js_area_check, .js_pref_check, .js_siku_check, .js_city_check").each(function() {
        var $this = $(this);
        var hasManyCheckboxClass = $this.data('hasManyCheckboxClass');
        if (hasManyCheckboxClass === undefined) {
          return;
        }
        var $hasManyCheckboxes = $rootDom.find('.'+hasManyCheckboxClass);
        $this.data('hasManyCheckboxes', $hasManyCheckboxes);
        $hasManyCheckboxes.data('siblingCheckboxes', $hasManyCheckboxes);
        $hasManyCheckboxes.data('belongsToCheckbox', $this);
      })    
      
      if(selFlg == true){
        _this.selectJudge_init($rootDom);
      }else{
        $(".choosing").remove();
      }
  
  },
  


  init : function($rootDom){
    
    var _this = this;

    // 変数束ねる用の兄弟定義
    $rootDom.find(".js_area_check, .js_pref_check, .js_siku_check, .js_city_check").each(function() {
        var $this = $(this);
        var hasManyCheckboxClass = $this.data('hasManyCheckboxClass');
        if (hasManyCheckboxClass === undefined) {
          return;
        }
        var $hasManyCheckboxes = $rootDom.find('.'+hasManyCheckboxClass);
        $this.data('hasManyCheckboxes', $hasManyCheckboxes);
        $hasManyCheckboxes.data('siblingCheckboxes', $hasManyCheckboxes);
        $hasManyCheckboxes.data('belongsToCheckbox', $this);
      })    
      
    _this.selectJudge_init($rootDom);
  
  },

  judge100 : function($rootDom,showAlert){
      var _this = this;
      // TENSHOKU_UNYO_DEV-3935 CoreWebVitals INP対策（getCategoryのcheckboxのチェック件数算出を停止）
/*       var _num = _this.getCategory($rootDom).number;
      if(_num > 100){
        _this.whenOver100($rootDom,showAlert);
      }else{
        _this.whenUnder100($rootDom,showAlert);
      }  
*/ 
      _this.whenUnder100($rootDom,showAlert);
  },
  
  whenOver100 : function(){
  },
  
  whenUnder100 : function(){
  },
  
  getCategory: function(obj){
      var _this = this;
      var textAry = [];
      var areaAry = [];
      var prefAry = [];
      var cityAry = [];
      var sikuAry = [];
      var _obj = [];
      obj.find(".js_area_check").each(function(){
      
        // 1. エリア単位
        if($(this).prop("checked") == true){
          areaAry.push($(this).val());
          textAry.push($(this).parent().text().replace(/\([0-9]*件\)/,"").replace("選択中",""));
          _obj.push($(this));
          
        }else{
          
          // 2. エリアにチェックが無ければ、その下位の都道府県チェックループ
          $(this).parents(".js_area_div").find(".js_pref_check").each(function(){
            var _prefname = $(this).parents("label").text().replace(/\([0-9]*件\)/,"").replace("選択中","");
            if($(this).prop("checked") == true){
              prefAry.push($(this).val());
              textAry.push($(this).parent().text().replace(/\([0-9]*件\)/,"").replace("選択中",""));
              _obj.push($(this));
            }else{

              // 3. 都道府県にチェックが無ければ、その下位の政令指定都市チェックループ
              $(this).parents(".js_pref_div").find(".js_city_check").each(function(){
                var _shiname = $(this).parents("label").text().replace(/\([0-9]*件\)/,"").replace("選択中","");
                if($(this).prop("checked") == true){
                  cityAry.push($(this).val());
                  textAry.push($(this).parent().text().replace(/\([0-9]*件\)/,"").replace("選択中",""));
                  _obj.push($(this));
                }else{
                  
                  // 4. 政令指定都市のチェックが無ければ、その下位の区もチェックループ
                  $(this).parents(".js_city_div").find(".js_siku_check:checked").each(function(){
                    sikuAry.push($(this).val());
                    textAry.push("<span class='subCategory area'>" +_shiname + "</span>" + $(this).parent().text().replace(/\([0-9]*件\)/,"").replace("選択中",""));
                    _obj.push($(this));
                      
                  })
                }
                
              })
              
              // 5. 政令指定都市以外ループ
              $(this).parents(".js_pref_div").find(".js_shigun_check:checked").each(function(){
                sikuAry.push($(this).val());
                _obj.push($(this));
                textAry.push("<span class='subCategory area'>" +_prefname + "</span>" + $(this).parent().text().replace(/\([0-9]*件\)/,"").replace("選択中",""));
              })
              
              
            }
          })
        }
      })

      var _number = areaAry.length + prefAry.length + cityAry.length + sikuAry.length;
      var _values = areaAry.concat(prefAry.concat(cityAry.concat(sikuAry)));
      return { number: _number, values : _values, obj : _obj, text: textAry };    
  },


  // 選択中かどうかの判別（都道府県）
  selectJudge : function($chkbox){
      var _this = this;
    
    
    // 都道府県の「選択中」と、エリアの「選択中」
    if($chkbox.hasClass("js_area_check")){
      //エリアチェックなら強制的に
      $chkbox.parents(".js_area_div").find(".js_pref_title").each(function(){
        if($chkbox.prop("checked")){
          $chkbox.parents(".js_categoryRoot").find(".js_categorySelectIndicator").removeClass("be_hide").removeClass("js_beHide");
          $(this).find(".js_categorySelectIndicator_pref").removeClass("be_hide").removeClass("js_beHide");
        }else{
          $chkbox.parents(".js_categoryRoot").find(".js_categorySelectIndicator").addClass("be_hide").addClass("js_beHide");
          $(this).find(".js_categorySelectIndicator_pref").addClass("be_hide").addClass("js_beHide");
        }
      })
    
    }else{
      //都道府県チェックも強制的に
      if($chkbox.hasClass("js_pref_check")){
        if($chkbox.prop("checked")){
          $chkbox.parents(".js_pref_title").find(".js_categorySelectIndicator_pref").removeClass("be_hide").removeClass("js_beHide");
          $chkbox.parents(".js_categoryRoot").find(".js_categorySelectIndicator").removeClass("be_hide").removeClass("js_beHide");
        }else{
          $chkbox.parents(".js_pref_title").find(".js_categorySelectIndicator_pref").addClass("be_hide").addClass("js_beHide");
          if($chkbox.parents(".js_area_div").find(":checked").size() == 0){
            $chkbox.parents(".js_categoryRoot").find(".js_categorySelectIndicator").addClass("be_hide").addClass("js_beHide");
          }
        }
        
      }else{
        // 都道府県以下チェック
        if($chkbox.parents(".js_pref_div").find(":checked").size() > 0){
          $chkbox.parents(".js_pref_div").find(".js_pref_title").find(".js_categorySelectIndicator_pref").removeClass("be_hide").removeClass("js_beHide");
          $chkbox.parents(".js_categoryRoot").find(".js_categorySelectIndicator").removeClass("be_hide").removeClass("js_beHide");
        }else{
          $chkbox.parents(".js_pref_div").find(".js_pref_title").find(".js_categorySelectIndicator_pref").addClass("be_hide").addClass("js_beHide");
          if($chkbox.parents(".js_area_div").find(":checked").size() == 0){
            $chkbox.parents(".js_categoryRoot").find(".js_categorySelectIndicator").addClass("be_hide").addClass("js_beHide");
          }
        }
      }
    }
    
  },
  
  selectJudge_init : function($rootDom){
      var _this = this;
    console.log("init");
    // エリアごとのループ
    $rootDom.find(".js_area_div").each(function(){
      var $areaDiv = $(this);
      if($areaDiv.find(".js_area_check").prop("checked")){
        // エリアチェックがONなら全部ON！
        $areaDiv.parents(".js_categoryRoot").find(".js_categorySelectIndicator").removeClass("be_hide").removeClass("js_beHide");
        $areaDiv.find(".js_categorySelectIndicator_pref").removeClass("be_hide").removeClass("js_beHide");
        _this.areaLumpCheck_chk($areaDiv.find(".js_area_check"));
      }else if($areaDiv.find(":checked").size() > 0){
        // そうでなくとも、エリア内に一個でもチェックがあれば、エリアの「選択中」はON
        $areaDiv.parents(".js_categoryRoot").find(".js_categorySelectIndicator").removeClass("be_hide").removeClass("js_beHide");
        // そして県単位のチェックを開始
        $areaDiv.find(".js_pref_div").each(function(){
          if($(this).find(":checked").size() > 0){
            $(this).find(".js_pref_title").find(".js_categorySelectIndicator_pref").removeClass("be_hide").removeClass("js_beHide");
          }
        })
      }
    })
    
    $rootDom.find(".js_pref_check:checked, .js_city_check:checked").each(function(){
        _this.areaLumpCheck_chk($(this));
    })

    
    
    
  }


  
}
