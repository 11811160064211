/* **********************************************************
/* sp_script_src/controller/global/header.js
/* [SP]グローバルヘッダー/ハンバーガーメニュー
/* **********************************************************/
var _loadingAlert = 0;

$(function(){

  $(".appHeader .btn").click(function () {
    $(this).parents(".appHeader").hide();
    $(this).parents(".appHeader").prev("#spHeader").removeClass("be_hide");
    // 今後表示しないように、クッキーに情報を保存
    var expire = new Date();
    expire.setTime(expire.getTime() + 1000 * 3600 * 336);
    document.cookie = 'APPBANNERDELETE=1;path = /; expires=' + expire.toUTCString() + '; ';
  });

  $('.js_headerMenu').click(function(){

    $(this).parents('#md_nav').hasClass('active');

    var header = $(this).parents('#md_nav');
    var hideMenu = $('#globalhideMenu');
    var overlay = $('#navWrap .overLay');

    if(header.hasClass('active')){
      header.removeClass('active');
      hideMenu.removeClass('active');
      overlay.removeClass('active');
      // Scroll OK
      $( 'body' ).attr( { style: '' } );
      $( 'html, body' ).prop( { scrollTop: current_scrollY } );
    }else{
      header.addClass('active');
      hideMenu.addClass('active');
      overlay.addClass('active');
      // No Scroll
      current_scrollY = $( window ).scrollTop();
      $( 'body' ).css({ position: 'fixed', width: '100%', top: 0});
    }
    return false;
  });

  $('.js_naviClose').click(function(){

    $('#md_nav').removeClass('active');
    $('#globalhideMenu').removeClass('active');
    $(this).removeClass('active');

  });

  $("#alertClose").click(function(){
    if(_loadingAlert == 0){
      _loadingAlert = 1;
        $("#alertHeader").css("position","absolute");
        var _h = (-1) * $(".js_siteAlerts").outerHeight() - 200;
        var dur = Number((-1) * _h) * 0.7;
      $(".js_spWrapper").show();
        $("html,body").scrollTop(0);
        $(".js_siteAlerts").css("overflow","hidden");
        $(".js_siteAlerts").animate({ top : _h} , dur, "",function(){
          $(".js_siteAlerts").addClass("be_hide");
          _loadingAlert = 0;
        });
        
      }
    
  })
})
var globalHeader = {
  
  init : function(){
      $(".js_siteAlerts").height($(window).height());
    var _sh = (-1) * Number($(".js_siteAlerts").outerHeight());
    $(".js_siteAlerts").css("top" , _sh+"px");
    $(".js_siteAlerts").data("sh",_sh);

    
  },
  _open : function(){
      if(_loadingAlert == 0){
        _loadingAlert = 1;
        $("html,body").scrollTop(0);
        $(".js_siteAlerts").removeClass("be_hide");  
        var dur = Number((-1) * $(".js_siteAlerts").data("sh")) * 0.1;
        $(".js_siteAlerts").animate({ top : -70} , 1500, "easeOutElastic",function(){
          $("#alertHeader").css("position","fixed");
          $(".js_siteAlerts").css("overflow","visible");
          _loadingAlert = 0;
        $(".info .newFrame").remove();
        globalHeader.alert_read_ajax();
        });
        $(".js_spWrapper").hide();
      }
  },

    alert_read_ajax : function(){
    if($("#alertReadAjax").size() > 0){
      var _url = $("#alertReadAjax").val();
      var _data = {};
      _data[TOKEN_FOR_AJAX_NAME] = TOKEN_FOR_AJAX;
            $.ajax({
            type: 'POST',
            url: _url,
              data : _data,
                cache: false,
                async: false,
                dataType: 'json'
            });
        }
    }
}