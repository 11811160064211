(function($) {
  $.fn.extend({
    bubbleUp: function() {
      var $this = $(this);
      var $bubbleContainer = $(this).parent().find('.js_bubble');
      var bubbleContainerOffsetX = ($this.width() - $bubbleContainer.width()) / 2;
      $bubbleContainer
        .css({ left: bubbleContainerOffsetX, top: -$bubbleContainer.height() / 2 })
        .removeClass('js_beHide')
        .addClass('js_bubble--enter')
        .delay(600)
        .queue(function() {
          $bubbleContainer.addClass('js_bubble--leave').dequeue();
          $bubbleContainer.on('oTransitionEnd mozTransitionEnd webkitTransitionEnd transitionend', function(event) {
            $bubbleContainer.off('oTransitionEnd mozTransitionEnd webkitTransitionEnd transitionend');
            $bubbleContainer.addClass('js_beHide');
          });
        })
    }
  });
})(jQuery);
