/**
 * UIView疑似クラスの管理。
 * 検索・トップに使うスライド遷移処理、それに伴う変数の取得処理などをまとめている。
 * !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
 * !!!!!!!!!!!!アプリでの遷移不具合解消のため、クラス定義まるごと切り分けています。!!!!!!!!!!!!
 * !!!!!!!!!!!!処理の修正を行うときは、アプリ側の対応も忘れずに行うこと!!!!!!!!!!!!!!!!!!!!!!!!
 * !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
 */


(function () {

  history.scrollRestoration = 'manual';
  function ucFirst(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

var AppExp = new RegExp(/#enApp\/([0-9]*\.[0-9]*\.[0-9]*)#/);
var ua = navigator.userAgent.toLowerCase();
if(ua.indexOf('enapp') <= -1){

// WEB用のクラス
  var UIView = function() {
    
    // uiViewクラスのコンストラクタ。実行される
    var _this = this;
    this.afterAppendAsyncContent = function() {};
    this.prepareForTransition = function() {};
    this.prepareForSegue = function() {};
    this.afterInitializeModalViewContents = function() {};
    this.afterDismissModalSegue = function(){};
    var $asyncContents = $('.js_asyncContent');
    var rootViewScrollPosition = 0;

    // 非同期画面を読み込む関数
    this.loadAsyncContent = function($asyncContent){
      if($asyncContent.hasClass("js_async_done")){
        return false;
      }else{
        $asyncContent.addClass("js_async_done");
      }
      var contentUrl = $asyncContent.data('contentUrl');
      var contentFetchParam = $asyncContent.data('contentFetchParam') || {};
      contentFetchParam[TOKEN_FOR_AJAX_NAME] = TOKEN_FOR_AJAX;
      $.ajax({
        url: contentUrl,
        method: 'GET',
        data: contentFetchParam,
        success: function(data, textStatus, jqXHR) {
          $asyncContent.append(data);
          $asyncContent.data('defaultHtml', data);
          _this.afterAppendAsyncContent($asyncContent);
        },
        fail : function(){
          $asyncContent.removeClass("js_async_done");
        }
      });      
    }

    
    this.$viewContainer = $('.js_uiViewContainer');
    var $activeViewGroup = this.$viewContainer;
    var $views = this.$viewContainer.find('.js_uiView');
    var segues = '.js_uiViewSegue';
    var $unwindSegues = this.$viewContainer.find('.js_uiViewUnwindSegue');
    var $dismissModalSegues = this.$viewContainer.find('.js_uiViewDismissModalSegue');
    this.$navigationBars = this.$viewContainer.find('.js_uiViewNavigationBar');
    var $activeView = $($views[0]);
    
    /**
     * summarize
     *
     * 親子関係のチェックボックスの入力値をまとめる
     *
     * 例.1 営業系すべてのみ（法人営業などは削除）
     * - [x] 営業系すべて
     * -- [x] 法人営業
     * -- [x] 個人営業
     * -- [x] ルートセールス、代理店営業
     * --- [x] ルートセールス・渉外・外商
     * --- [x] 代理店営業・アライアンス
     *
     * 例.2 法人営業とルートセールス、代理店営業（ルートセールス・渉外・外商などは削除）
     * - [ ] 営業系すべて
     * -- [x] 法人営業
     * -- [ ] 個人営業
     * -- [x] ルートセールス、代理店営業
     * --- [x] ルートセールス・渉外・外商
     * --- [x] 代理店営業・アライアンス
     */
    var summarize = function($associableInputs) {
      var $checkedAssociableInputs = $associableInputs.filter(':checked');
      
      $a = $checkedAssociableInputs.filter(function() {
        var $checkedAssociableInput = $(this);
        var $siblingCheckboxes = $checkedAssociableInput.data('siblingCheckboxes');
        if ($siblingCheckboxes === undefined) {
          return true;
        }
        var isAllSiblingCheckboxCheckded =
          $siblingCheckboxes.length === $siblingCheckboxes.filter(':checked').length;
        return !isAllSiblingCheckboxCheckded;
      });
      return $a;

    };
    this.inputs = function(filter) {
      filter = filter || '';
      
      // デフォルトで設定されたものをパラメータから取得する族
      var strFromFetch = "";
      var paramFromFetch = {};
      $activeViewGroup.find(".js_asyncContent").each(function(){
        if($(this).attr("data-content-fetch-param") != ""){
          strFromFetch = $(this).attr("data-content-fetch-param");
          return false;
        }
      })
      if(strFromFetch != ""){
        paramFromFetch = _searchDataObj.parse(strFromFetch);
      }
      
      // uiViewContainerのhiddenから取得する族
      var paramFromHidden = [];
      var exceptNames = [new RegExp("recomend.+"), new RegExp("recommend.+"), new RegExp("E13CT"), new RegExp("PK")];
      
      $(".js_uiViewContainer input[type=hidden]").each(function(){
        var _n = $(this).attr("name");
        var _v = $(this).val();
        var _flg = true;
        if(_n !== undefined){
          for(var _i in exceptNames){
            if(_n.match(exceptNames[_i])){
              _flg = false;
            }
          }
          if(_flg == true){
            paramFromHidden.push({ name : _n , value: _v});
          }
          
        }
      })
      
      // チェックボックスから取得する族
      $searchDOMs = $(".js_uiViewContainer .js_asyncContent.js_async_done");
      var separateInputs = $searchDOMs.find(':input:not(.js_associableCheckbox)'+filter);
      var associableInputs = $searchDOMs.find(':input:not([name="areaid[]"]).js_associableCheckbox'+filter);
      var summarizeAssociableInputs = summarize(associableInputs);
      //areaを足してみる。
      var paramFromChk = separateInputs.serializeArray().concat(summarizeAssociableInputs.serializeArray());
      var _output = "";
      $.each(common_area_search_sp.getCategory($activeViewGroup).obj,function(i,field){
        paramFromChk.push({name : "areaid[]", value: field.val() });
        _output += "_" + field.val();
      })
      
      
      // マージする（fromChkを優先）
      var paramMerged = [];
      var names = [];
      for(var _i in paramFromChk){
        var tmp = paramFromChk[_i].name;
        if(names.indexOf(tmp) == -1){
          names.push(tmp);
        }
      }
      // 重複してない奴だけ入れてく
      for(var _n in paramFromFetch){
        var tmp = paramFromFetch[_n].name;
        if(names.indexOf(tmp) == -1){
          paramMerged.push(paramFromFetch[_n]);
        }
      }
      // 最後に合体させて
      var _rtn  = paramMerged.concat(paramFromChk.concat(paramFromHidden));
      
      // 職種が無かったら空を入れとく
      var _flg = false;
      for(var _f in _rtn){
        if(_rtn[_f].name=="occupation[]"){
          _flg = true
        }
      }
      if(_flg == false){
        _rtn.push({name : "occupation[]" , value : ""});
      }
      
      _searchDataObj.replaceData(_rtn);
      return _rtn;
    };
    
    var $destinationView = '';
    var historyStateToViewId = function (historyState) {
      /**
       * [usage]
       *
       * #default          :historyState
       * v
       * default           :tmp
       * v
       * #js_uiViewDefault :viewId
       */
      var tmp = historyState === null || historyState == undefined ? location.hash.replace(/#/, '') : historyState.replace(/#/, '');
      var viewId = '#js_uiView'+ucFirst(tmp);
      return viewId;
    };

    var defaultViewState = {
      destinationViewIdentification: 'root',
      transition: 'none',
      historyIndex: history.length,
      presentModalyHistoryIndex: history.length,
      params: {}
    };
    var currentViewState = history.state === null || history.state == undefined ? defaultViewState : history.state;
    if (history.state === null && location.hash !== '') {
      currentViewState.destinationViewIdentification = location.hash.replace(/#/, '');
    }
    this.state = function() {
      return currentViewState;
    }
		this.$findCurrentActiveGroup = function(selector) {
			return $activeViewGroup.find(selector);
		}
    var prevViewState = currentViewState;
    this.extendCurrentViewState = function(viewState) {
      extendViewState = $.extend(currentViewState, viewState);
      history.replaceState(extendViewState, null, '#'+currentViewState.destinationViewIdentification);
    };

    // viewを切り替えるアニメーション処理。
    var representationViewFromHistoryState = function (option) {
      var option = $.extend({ forceDisableAnimation: false }, option);
      $destinationView = $(historyStateToViewId(currentViewState.destinationViewIdentification));
      
      if ($destinationView.is($activeView) || option.forceDisableAnimation) {
        updateActiveView();
        if($destinationView.find(".js_asyncContent").size() > 0){
          _this.loadAsyncContent($destinationView.find(".js_asyncContent"));
        }
        return;
      }
      var transitionDirection = currentViewState.historyIndex > prevViewState.historyIndex ? 'forward' : 'back';
      _this.prepareForTransition(currentViewState, prevViewState, transitionDirection);
      if (transitionDirection === 'forward' && currentViewState.transition === 'push' ) {
        $destinationView.addClass('js_uiView--state-transition js_uiView--prepare-transition--transition-push');
        _this.$viewContainer.addClass('js_uiViewContainer--state-transition')
          .delay(10)
          .queue(function() {
            _this.$viewContainer.addClass('js_uiViewContainer--transition-push-enter').dequeue();
            _this.loadAsyncContent($destinationView.find(".js_asyncContent"));

          });
        _this.$viewContainer.on('oTransitionEnd mozTransitionEnd webkitTransitionEnd transitionend', function(event){
          _this.$viewContainer.off('oTransitionEnd mozTransitionEnd webkitTransitionEnd transitionend');
          updateActiveView();
        });
      } else if (transitionDirection === 'forward' && currentViewState.transition === 'presentModaly') {
        $destinationView
          .on('oTransitionEnd mozTransitionEnd webkitTransitionEnd transitionend', function(event){
            $destinationView.off('oTransitionEnd mozTransitionEnd webkitTransitionEnd transitionend');
            updateActiveView();
          });
          
        $destinationView
          .addClass('js_uiView--state-transition js_uiView--prepare-transition--transition-present-modaly')
          .delay(100)
          .queue(function() {
            $destinationView.addClass('js_uiView--start-transition--transition-present-modaly').dequeue();
            _this.loadAsyncContent($destinationView.find(".js_asyncContent"));
          });
          
      } else if (transitionDirection === 'back' && prevViewState.transition === 'push') {
        $destinationView.addClass('js_uiView--state-transition js_uiView--prepare-transition--transition-push-back');
        _this.$viewContainer.addClass('js_uiViewContainer--state-transition')
          .delay(10)
          .queue(function() {
            _this.$viewContainer.addClass('js_uiViewContainer--transition-push-leave').dequeue();
            _this.loadAsyncContent($destinationView.find(".js_asyncContent"));
          });
        _this.$viewContainer.on('oTransitionEnd mozTransitionEnd webkitTransitionEnd transitionend', function(event){
          _this.$viewContainer.off('oTransitionEnd mozTransitionEnd webkitTransitionEnd transitionend');
          updateActiveView();
        });
      } else if (transitionDirection === 'back' && prevViewState.transition === 'presentModaly') {
        $destinationView.addClass('js_uiView--state-active');
        $activeView
          .addClass('js_uiView--state-transition js_uiView--prepare-transition--transition-close-present-modaly')
          .delay(10)
          .queue(function() {
            $activeView.addClass('js_uiView--start-transition--transition-close-present-modaly').dequeue();
            _this.loadAsyncContent($destinationView.find(".js_asyncContent"));
          });
        $activeView.on('oTransitionEnd mozTransitionEnd webkitTransitionEnd transitionend', function(event){
          $activeView.off('oTransitionEnd mozTransitionEnd webkitTransitionEnd transitionend');
          updateActiveView();
          initializeModalViewContents();
        });
      }
      
    };
    var updateActiveView= function() {
      $activeView.removeClass(function(index, className) {
        return (className.match(/\bjs_uiView--\S+/g) || []).join(' ');
      });
      $destinationView.removeClass(function(index, className) {
        return (className.match(/\bjs_uiView--\S+/g) || []).join(' ');
      });
      _this.$viewContainer.removeClass(function(index, className) {
        return (className.match(/\bjs_uiViewContainer--\S+/g) || []).join(' ');
      });
      $destinationView.addClass('js_uiView--state-active');
      $activeView = $destinationView;
      if (currentViewState.destinationViewIdentification === 'root') {
        scrollTo(0, rootViewScrollPosition);
      }
      $activeViewGroup = $activeView.parent().length > 0 ? $activeView.parent() : _this.$viewContainer;
    };
    
    representationViewFromHistoryState({ forceDisableAnimation: true });


// js_uiViewSegue要素をクリックしたときのイベント
    this.$viewContainer.on('click', segues, function(event) {
      event.preventDefault();
      var prepareResult = _this.prepareForSegue($(this));
      
      if (prepareResult === 'cancelSegue') {
        return false;
      }
      var $this = $(this);
      var destinationViewIdentification = $this.data('destinationViewIdentification');
      var transition = $this.data('transition');
      
      presentModalyHistoryIndex = transition === 'presentModaly' ? currentViewState.historyIndex : currentViewState.presentModalyHistoryIndex;
      rootViewScrollPosition = history.state === null ? $(window).scrollTop() : rootViewScrollPosition;
      var viewState = {
        destinationViewIdentification: destinationViewIdentification,
        animation: true,
        transition: transition,
        historyIndex: currentViewState.historyIndex+1,
        presentModalyHistoryIndex: presentModalyHistoryIndex
      };
      
      scrollTo(0,0);
      
      setTimeout(function() {
        // pushStateで戻れるようにする
        history.pushState(viewState, null, '#'+destinationViewIdentification);
        prevViewState = currentViewState;
        currentViewState = viewState;
        representationViewFromHistoryState();
      }, 100);
      
      
    });


// 決定、などでひとつ前のuiViewに戻るときのイベント
    $unwindSegues.on('click', function(event) {
      //「決定」とかで戻るとき、か
      if($(this).hasClass("js_disabled")){
        if($(this).attr("data-errorMessage") != "" && $(this).attr("data-errorMessage") !== undefined){
          alert($(this).attr("data-errorMessage").replace("\\n","\n"));
        }
        return false;
      }
      event.preventDefault();
      history.back(); // to trigger popstate event
    });
    
    var initializeModalViewContents = function() {
      $('.js_searchKeyword').val('');
      $asyncContents.each(function() {
        var $asyncContent = $(this);
        $asyncContent.html($asyncContent.data('defaultHtml'));
        _this.afterAppendAsyncContent($asyncContent);
      });
      _this.afterInitializeModalViewContents();
    };
    $dismissModalSegues.on('click', function(event) {
      if(!$(this).hasClass("js_disabled")){
        event.preventDefault();
        if (currentViewState.presentModalyHistoryIndex === currentViewState.historyIndex) {
          location.href = _this.$viewContainer.data('rescueUrl');
          return;
        }
        var replaceState = $.extend(currentViewState, { transition: 'presentModaly' } );
        history.replaceState(replaceState, null, replaceState.currentViewIdentification);
        _this.afterDismissModalSegue();
        history.go(-(currentViewState.historyIndex - currentViewState.presentModalyHistoryIndex));
      }
    
    });
    /**
     * Arrowsの機種依存の不具合対応のためにsetTimeoutを使用しています。
     * 該当Redmine
     * http://165.100.241.116/issues/9989
     * http://165.100.241.116/issues/9985
     */
    setTimeout(function() {
      $(window).on('popstate', function(event) {
        prevViewState = currentViewState;
        currentViewState = event.originalEvent.state || defaultViewState;
        representationViewFromHistoryState();
      });
    }, 10);
  };
  
}else{
  /************************************************************* アプリ ****************************************************************/
  /************************************************************* アプリ ****************************************************************/
  /************************************************************* アプリ ****************************************************************/
  /************************************************************* アプリ ****************************************************************/
  /************************************************************* アプリ ****************************************************************/
  
  var dummyHistoryClass = function(){
    var _this = this;
    this.ary = [];
    this.state = null;
    this.initState = function(){
      
      var _root = location.hash.replace(/#/, '');
      var _url  = location.hash.replace(/#/, '');
      if(_root == ""){
        _root = "root";
      }
      
      _this.ary = [
        {state : {
            destinationViewIdentification: _root,
            transition: 'none',
            historyIndex: 0,
            presentModalyHistoryIndex: 0,
            params: {}      
          },
          title : null,
          url : _url
        }
      ];
      
      
      
    }
    this.replaceState = function(_state,_title,_url){
      _this.ary.pop();
      _this.ary.push({state:_state,title:_title,url:_url});
    }
    this.pushState = function(_state,_title,_url){
      _this.ary.push({state:_state,title:_title,url:_url});
    }
    this.length = function(){
      return _this.ary.length;
    }
    this.popary = function(){
      _this.ary.pop();
      return _this.ary[_this.ary.length - 1];
    }
    this.closeModal = function(){
      _this.ary = [];
    }
  }
  var _history = new dummyHistoryClass();
  _history.initState();
  console.log(_history.length());

  
  // アプリ用のクラス
  var UIView = function() {
    
    // uiViewクラスのコンストラクタ。実行される
    var _this = this;
    
    this.afterAppendAsyncContent = function() {};
    this.prepareForTransition = function() {};
    this.prepareForSegue = function() {};
    this.afterInitializeModalViewContents = function() {};
    this.afterDismissModalSegue = function(){};
    var $asyncContents = $('.js_asyncContent');
    var rootViewScrollPosition = 0;

    // 非同期画面を読み込む関数
    this.loadAsyncContent = function($asyncContent){
      if($asyncContent.hasClass("js_async_done")){
        return false;
      }else{
        $asyncContent.addClass("js_async_done");
      }
      var contentUrl = $asyncContent.data('contentUrl');
      var contentFetchParam = $asyncContent.data('contentFetchParam') || {};
      contentFetchParam[TOKEN_FOR_AJAX_NAME] = TOKEN_FOR_AJAX;
      $.ajax({
        url: contentUrl,
        method: 'GET',
        data: contentFetchParam,
        success: function(data, textStatus, jqXHR) {
          $asyncContent.append(data);
          $asyncContent.data('defaultHtml', data);
          _this.afterAppendAsyncContent($asyncContent);
        },
        fail : function(){
          $asyncContent.removeClass("js_async_done");
        }
      });      
    }


    this.$viewContainer = $('.js_uiViewContainer');
    var $activeViewGroup = this.$viewContainer;
    var $views = this.$viewContainer.find('.js_uiView');
    var segues = '.js_uiViewSegue';
    var $unwindSegues = this.$viewContainer.find('.js_uiViewUnwindSegue');
    var $dismissModalSegues = this.$viewContainer.find('.js_uiViewDismissModalSegue');
    this.$navigationBars = this.$viewContainer.find('.js_uiViewNavigationBar');
    var $activeView = $($views[0]);
    
    /**
     * summarize
     *
     * 親子関係のチェックボックスの入力値をまとめる
     *
     * 例.1 営業系すべてのみ（法人営業などは削除）
     * - [x] 営業系すべて
     * -- [x] 法人営業
     * -- [x] 個人営業
     * -- [x] ルートセールス、代理店営業
     * --- [x] ルートセールス・渉外・外商
     * --- [x] 代理店営業・アライアンス
     *
     * 例.2 法人営業とルートセールス、代理店営業（ルートセールス・渉外・外商などは削除）
     * - [ ] 営業系すべて
     * -- [x] 法人営業
     * -- [ ] 個人営業
     * -- [x] ルートセールス、代理店営業
     * --- [x] ルートセールス・渉外・外商
     * --- [x] 代理店営業・アライアンス
     */
    var summarize = function($associableInputs) {
      var $checkedAssociableInputs = $associableInputs.filter(':checked');
      
      $a = $checkedAssociableInputs.filter(function() {
        var $checkedAssociableInput = $(this);
        var $siblingCheckboxes = $checkedAssociableInput.data('siblingCheckboxes');
        if ($siblingCheckboxes === undefined) {
          return true;
        }
        var isAllSiblingCheckboxCheckded =
          $siblingCheckboxes.length === $siblingCheckboxes.filter(':checked').length;
        return !isAllSiblingCheckboxCheckded;
      });
      return $a;
      /*
      
      return $checkedAssociableInputs.filter(function() {
        var $checkedAssociableInput = $(this);
        var $siblingCheckboxes = $checkedAssociableInput.data('siblingCheckboxes');
        console.log($siblingCheckboxes);
        if ($siblingCheckboxes === undefined) {
          return true;
        }
        var isAllSiblingCheckboxCheckded =
          $siblingCheckboxes.length === $siblingCheckboxes.filter(':checked').length;
        return !isAllSiblingCheckboxCheckded;
      });
      */
    };
    this.inputs = function(filter) {
      filter = filter || '';
      
      // デフォルトで設定されたものをパラメータから取得する族
      var strFromFetch = "";
      var paramFromFetch = {};
      $activeViewGroup.find(".js_asyncContent").each(function(){
        if($(this).attr("data-content-fetch-param") != ""){
          strFromFetch = $(this).attr("data-content-fetch-param");
          return false;
        }
      })
      if(strFromFetch != ""){
        paramFromFetch = _searchDataObj.parse(strFromFetch);
      }
      
      // uiViewContainerのhiddenから取得する族
      var paramFromHidden = [];
      var exceptNames = [new RegExp("recomend.+"), new RegExp("recommend.+"), new RegExp("E13CT"), new RegExp("PK")];
      
      $(".js_uiViewContainer input[type=hidden]").each(function(){
        var _n = $(this).attr("name");
        var _v = $(this).val();
        var _flg = true;
        if(_n !== undefined){
          for(var _i in exceptNames){
            if(_n.match(exceptNames[_i])){
              _flg = false;
            }
          }
          if(_flg == true){
            paramFromHidden.push({ name : _n , value: _v});
          }
          
        }
      })
      
      // チェックボックスから取得する族
      $searchDOMs = $(".js_uiViewContainer .js_asyncContent.js_async_done");
      var separateInputs = $searchDOMs.find(':input:not(.js_associableCheckbox)'+filter);
      var associableInputs = $searchDOMs.find(':input:not([name="areaid[]"]).js_associableCheckbox'+filter);
      var summarizeAssociableInputs = summarize(associableInputs);
      //areaを足してみる。
      var paramFromChk = separateInputs.serializeArray().concat(summarizeAssociableInputs.serializeArray());
      var _output = "";
      $.each(common_area_search_sp.getCategory($activeViewGroup).obj,function(i,field){
        paramFromChk.push({name : "areaid[]", value: field.val() });
        _output += "_" + field.val();
      })
      
      
      // マージする（fromChkを優先）
      var paramMerged = [];
      var names = [];
      for(var _i in paramFromChk){
        var tmp = paramFromChk[_i].name;
        if(names.indexOf(tmp) == -1){
          names.push(tmp);
        }
      }
      // 重複してない奴だけ入れてく
      for(var _n in paramFromFetch){
        var tmp = paramFromFetch[_n].name;
        if(names.indexOf(tmp) == -1){
          paramMerged.push(paramFromFetch[_n]);
        }
      }
      // 最後に合体させて
      var _rtn  = paramMerged.concat(paramFromChk.concat(paramFromHidden));
      
      // 職種が無かったら空を入れとく
      var _flg = false;
      for(var _f in _rtn){
        if(_rtn[_f].name=="occupation[]"){
          _flg = true
        }
      }
      if(_flg == false){
        _rtn.push({name : "occupation[]" , value : ""});
      }
      
      _searchDataObj.replaceData(_rtn);
      return _rtn;
    };
    var $destinationView = '';
    var historyStateToViewId = function (historyState) {
      /**
       * [usage]
       *
       * #default          :historyState
       * v
       * default           :tmp
       * v
       * #js_uiViewDefault :viewId
       */
      var tmp = historyState === null || historyState == undefined ? location.hash.replace(/#/, '') : historyState.replace(/#/, '');
      var viewId = '#js_uiView'+ucFirst(tmp);
      return viewId;
    };


      var _root = location.hash.replace(/#/, '');
      if(_root == ""){
        _root = "root";
      }

    var defaultViewState = {
      destinationViewIdentification: _root,
      transition: 'none',
      historyIndex: _history.length(),
      presentModalyHistoryIndex: _history.length(),
      params: {}
    };
    var currentViewState = _history.state === null || _history.state == undefined ? defaultViewState : _history.state;
    if (history.state === null && location.hash !== '') {
      currentViewState.destinationViewIdentification = location.hash.replace(/#/, '');
    }
    this.state = function() {
      return currentViewState;
    }
		this.$findCurrentActiveGroup = function(selector) {
			return $activeViewGroup.find(selector);
		}
    var prevViewState = currentViewState;
    this.extendCurrentViewState = function(viewState) {
      extendViewState = $.extend(currentViewState, viewState);
      _history.replaceState(extendViewState, null, '#'+currentViewState.destinationViewIdentification);
    };

    // viewを切り替えるアニメーション処理。
    var representationViewFromHistoryState = function (option) {
      var option = $.extend({ forceDisableAnimation: false }, option);
      $destinationView = $(historyStateToViewId(currentViewState.destinationViewIdentification));
      // 切り替え先の非同期コンテンツをこのタイミングで読み込む
      _this.loadAsyncContent($destinationView.find(".js_asyncContent"));
      
      if ($destinationView.is($activeView) || option.forceDisableAnimation) {
        updateActiveView();
        return;
      }
      console.log($destinationView);
      if(option.transitionDirection === undefined){
        var transitionDirection = currentViewState.historyIndex > prevViewState.historyIndex ? 'forward' : 'back';
      }else{
        var transitionDirection = 'back';
      }
      _this.prepareForTransition(currentViewState, prevViewState, transitionDirection);
      if (transitionDirection === 'forward' && currentViewState.transition === 'push' ) {
        $destinationView.addClass('js_uiView--state-transition js_uiView--prepare-transition--transition-push');
        _this.$viewContainer.addClass('js_uiViewContainer--state-transition')
          .delay(10)
          .queue(function() {
            _this.$viewContainer.addClass('js_uiViewContainer--transition-push-enter').dequeue();
          });
        _this.$viewContainer.on('oTransitionEnd mozTransitionEnd webkitTransitionEnd transitionend', function(event){
          _this.$viewContainer.off('oTransitionEnd mozTransitionEnd webkitTransitionEnd transitionend');
          updateActiveView();
        });
      } else if (transitionDirection === 'forward' && currentViewState.transition === 'presentModaly') {
        $destinationView
          .on('oTransitionEnd mozTransitionEnd webkitTransitionEnd transitionend', function(event){
            $destinationView.off('oTransitionEnd mozTransitionEnd webkitTransitionEnd transitionend');
            updateActiveView();
          });
          
        $destinationView
          .addClass('js_uiView--state-transition js_uiView--prepare-transition--transition-present-modaly')
          .delay(100)
          .queue(function() {
            $destinationView.addClass('js_uiView--start-transition--transition-present-modaly').dequeue();
          });
          
      } else if (transitionDirection === 'back' && prevViewState.transition === 'push') {
        $destinationView.addClass('js_uiView--state-transition js_uiView--prepare-transition--transition-push-back');
        _this.$viewContainer.addClass('js_uiViewContainer--state-transition')
          .delay(10)
          .queue(function() {
            _this.$viewContainer.addClass('js_uiViewContainer--transition-push-leave').dequeue();
          });
        _this.$viewContainer.on('oTransitionEnd mozTransitionEnd webkitTransitionEnd transitionend', function(event){
          _this.$viewContainer.off('oTransitionEnd mozTransitionEnd webkitTransitionEnd transitionend');
          updateActiveView();
        });
      } else if (transitionDirection === 'back' && prevViewState.transition === 'presentModaly') {
        $destinationView.addClass('js_uiView--state-active');
        $activeView
          .addClass('js_uiView--state-transition js_uiView--prepare-transition--transition-close-present-modaly')
          .delay(10)
          .queue(function() {
            $activeView.addClass('js_uiView--start-transition--transition-close-present-modaly').dequeue();
          });
        $activeView.on('oTransitionEnd mozTransitionEnd webkitTransitionEnd transitionend', function(event){
          $activeView.off('oTransitionEnd mozTransitionEnd webkitTransitionEnd transitionend');
          updateActiveView();
          initializeModalViewContents();
        });
      }
      
    };
    var updateActiveView= function() {
      $activeView.removeClass(function(index, className) {
        return (className.match(/\bjs_uiView--\S+/g) || []).join(' ');
      });
      $destinationView.removeClass(function(index, className) {
        return (className.match(/\bjs_uiView--\S+/g) || []).join(' ');
      });
      _this.$viewContainer.removeClass(function(index, className) {
        return (className.match(/\bjs_uiViewContainer--\S+/g) || []).join(' ');
      });
      $destinationView.addClass('js_uiView--state-active');
      $activeView = $destinationView;
      if (currentViewState.destinationViewIdentification === 'root') {
        scrollTo(0, rootViewScrollPosition);
      }
      $activeViewGroup = $activeView.parent().length > 0 ? $activeView.parent() : _this.$viewContainer;
    };
    
    representationViewFromHistoryState({ forceDisableAnimation: true });


// js_uiViewSegue要素をクリックしたときのイベント
    this.$viewContainer.on('click', segues, function(event) {
      event.preventDefault();
      var prepareResult = _this.prepareForSegue($(this));
      if (prepareResult === 'cancelSegue') {
        return false;
      }
      var $this = $(this);
      var destinationViewIdentification = $this.data('destinationViewIdentification');
      var transition = $this.data('transition');
      
      presentModalyHistoryIndex = transition === 'presentModaly' ? currentViewState.historyIndex : currentViewState.presentModalyHistoryIndex;
      rootViewScrollPosition = _history.state === null ? $(window).scrollTop() : rootViewScrollPosition;
      var viewState = {
        destinationViewIdentification: destinationViewIdentification,
        animation: true,
        transition: transition,
        historyIndex: currentViewState.historyIndex+1,
        presentModalyHistoryIndex: presentModalyHistoryIndex
      };
      
      scrollTo(0,0);
      
      setTimeout(function() {
        // pushStateで戻れるようにする
        _history.pushState(viewState, null, '#'+destinationViewIdentification);
        prevViewState = currentViewState;
        currentViewState = viewState;
        console.log(_history);
        representationViewFromHistoryState();

      }, 100);
      
    });


// 決定、などでひとつ前のuiViewに戻るときのイベント
    $unwindSegues.on('click', function(event) {
      //「決定」とかで戻るとき、か
      if($(this).hasClass("js_disabled")){
        if($(this).attr("data-errorMessage") != "" && $(this).attr("data-errorMessage") !== undefined){
          alert($(this).attr("data-errorMessage").replace("\\n","\n"));
        }
        return false;
      }
      event.preventDefault();
      // 戻らない！すすむ
      var prepareResult = _this.prepareForSegue($(this));
      if (prepareResult === 'cancelSegue') {
        return false;
      }
      console.log(_history);
      var $this = $(this);
      var prv = _history.popary();
      console.log(prv);
      var destinationViewIdentification = prv.state.destinationViewIdentification;
      var transition = prv.state.transition;
      
      presentModalyHistoryIndex = transition === 'presentModaly' ? currentViewState.historyIndex : currentViewState.presentModalyHistoryIndex;
      rootViewScrollPosition = _history.state === null ? $(window).scrollTop() : rootViewScrollPosition;
      var viewState = {
        destinationViewIdentification: destinationViewIdentification,
        animation: true,
        transition: transition,
        historyIndex: currentViewState.historyIndex+1,
        presentModalyHistoryIndex: presentModalyHistoryIndex,
      };
      scrollTo(0,0);
      
      setTimeout(function() {
        // pushStateで戻れるようにする
        _history.pushState(viewState, null, '#'+destinationViewIdentification);
        prevViewState = currentViewState;
        currentViewState = viewState;
        representationViewFromHistoryState({transitionDirection : "back"});
        
      }, 100);

    });
    
    var initializeModalViewContents = function() {
      $('.js_searchKeyword').val('');
      $asyncContents.each(function() {
        var $asyncContent = $(this);
        $asyncContent.html($asyncContent.data('defaultHtml'));
        _this.afterAppendAsyncContent($asyncContent);
      });
      _this.afterInitializeModalViewContents();
    };
    $dismissModalSegues.on('click', function(event) {
      event.preventDefault();
      if (currentViewState.presentModalyHistoryIndex === currentViewState.historyIndex) {
        location.href = _this.$viewContainer.data('rescueUrl');
        return;
      }
      var replaceState = $.extend(currentViewState, { transition: 'presentModaly' } );
      _history.replaceState(replaceState, null, replaceState.currentViewIdentification);
      _this.afterDismissModalSegue();

      event.preventDefault();
      
      // 全部消して閉じる
      var prepareResult = _this.prepareForSegue($(this));
      if (prepareResult === 'cancelSegue') {
        return false;
      }
      var $this = $(this);
      var _root = location.hash.replace(/#/, '');
      var _url  = location.hash.replace(/#/, '');
      
      if(_root == ""){
        _root = "root";
      }
      _root = _root.charAt(0).toUpperCase() + _root.substring(1).toLowerCase();
      
      
      var destinationViewIdentification = _root;
      var transition = "presentModaly";
      
      presentModalyHistoryIndex = transition === 'presentModaly' ? currentViewState.historyIndex : currentViewState.presentModalyHistoryIndex;
      rootViewScrollPosition = _history.state === null ? $(window).scrollTop() : rootViewScrollPosition;
      var viewState = {
        destinationViewIdentification: destinationViewIdentification,
        animation: true,
        transition: transition,
        historyIndex: currentViewState.historyIndex+1,
        presentModalyHistoryIndex: presentModalyHistoryIndex,
      };
      scrollTo(0,0);
      
      setTimeout(function() {
        // pushStateで戻れるようにする
        _history.pushState(viewState, null, '#'+destinationViewIdentification);
        prevViewState = currentViewState;
        currentViewState = viewState;
        representationViewFromHistoryState({transitionDirection : "back"});
        
      }, 100);

//      _history.go(-(currentViewState.historyIndex - currentViewState.presentModalyHistoryIndex));
    
    });
    /**
     * Arrowsの機種依存の不具合対応のためにsetTimeoutを使用しています。
     * 該当Redmine
     * http://165.100.241.116/issues/9989
     * http://165.100.241.116/issues/9985
     */
    setTimeout(function() {
      $(window).on('popstate', function(event) {
        prevViewState = currentViewState;
        currentViewState = event.originalEvent.state || defaultViewState;
        representationViewFromHistoryState();
      });
    }, 10);
  };
}
  
  window.UIView = UIView;
  
  
  
  
  
  
  
  
  
})();
